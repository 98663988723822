import { ArrayElement, PagedResults } from './common'

export interface EventLogInfo {
  id: number
  created: Date
  user_id: number
  type: EventType
  subject_id: number
  ip_address: string | null
  info: string | null
  username: string
  school_name: string
}

export const EventType = ['log_in', 'user_deleted', 'school_deleted', 'assignment_deleted', 'assignment_pupil_removed', 'password_changed', 'honeypots_spent', 'group_pupil_removed', 'group_teacher_removed', 'group_deleted', 'school_user_removed', 'league_deleted', 'list_deleted', 'subscription_created', 'subscription_cancelled', 'reward_record_deleted', 'pupil_updated', 'school_org_type_updated', 'subscription_attempted', 'subscription_payment_challenged', 'subscription_payment_failed', 'subscription_activated', 'subscription_deactivated'] as const
export type EventType = ArrayElement<typeof EventType>

export interface GetEventsParams {
  readonly user_id?: number
  readonly created_after?: Date
  readonly created_before?: Date
  readonly types?: readonly EventType[]
  readonly subject_id?: number
  readonly ip_address?: string
  readonly school_id?: number
}

export interface GetEventsResponse {
  events: PagedResults<EventLogInfo>
}
