import gb from './en-gb'
import { CommonLang } from './types'

const lang: CommonLang = {
  ...gb,
  name: 'IE',
  County: 'County / Region',
  SchoolDistricts: 'School Districts / trusts.',
  currency: '€',
  spellingPersonalPrice: '74.99',
  spellingTeacherPrice: '170.00',
  spellingPremiumPrice: '4.75',
  spellingAppPrice: '3.49',
  pupilsPerSpellingClassroom: '36',
  natCurriculum: 'Irish Primary Curriculum',
  natCurr2: 'Curriculum',
  customText: 'Click a title and scan the QR code to save the list to your lists in the Spelling Shed app.',
  spellingCurriculumKey: 'spelling-shed-scheme-ie',
  spellingCurriculumId: '9',
  mathsPersonalPrice: '74.99',
  mathsTeacherPrice: '170.00',
  mathsPremiumPrice: '4.75',
  mathsAppPrice: '3.49',
  pupilsPerMathsClassroom: '36'
}

export default lang
