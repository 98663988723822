/* eslint-disable camelcase */
import { DeepReadonly } from 'ts-essentials'
import { UserModel } from '.'
import { ArrayElement } from './common'

export interface Email {
  id: number
  subject: string
  body: string
  recipient_query: RecipientQuery
  delivered_count: number
  opened_count: number
  clicked_count: number
  owner_id: number
  creation_date: Date
  sent_date: Date | null
  status: number
  type: EmailType
  cached_to_id: number | null
}

export const RecipientType = ['test', 'superusers', 'admins', 'teachers', 'users', 'trialended'] as const
export type RecipientType = ArrayElement<typeof RecipientType>

export const EmailType = ['marketing', 'system'] as const
export type EmailType = ArrayElement<typeof EmailType>

export interface EmailEvent {
  id: number
  email_address: string
  event: string
  tag: string | null
  email_id: number | null
  user_id: number | null
  timestamp: Date
}

export interface EmailCount {
  id: number
  delivered_count: number
  opened_count: number
  clicked_count: number
}

export interface EmailHeader {
  'h:X-Mailgun-Variables'?: MailGunVariables
  'h:X-Mailgun-Track-Clicks'?: 'yes' | 'no'
  'h:X-Mailgun-Track-Opens'?: 'yes' | 'no'
}

// these variables are passed back to our system when an event is logged
export interface MailGunVariables {
  user_id?: string // string so that recipient.user_id can be specified
  email_id?: string // string so that recipient.email_id can be specified
  tag?: string
  log?: boolean // determines whether our API logs the events from this email
}

// an array of Recipient Variables indexed to each user's email provides customisation within the email
export interface RecipientVariables {
  user_id?: number
  email_id?: number
  name?: string
  encodedEmail?: string
  verifyEmail?: string
  footer: string
  from: string
}

export interface SendableEmail {
  to: string | string[]
  from: string
  subject: string
  html: string
  'recipient-variables': string
  'h:X-Mailgun-Variables'?: string
  'h:X-Mailgun-Track-Clicks'?: 'yes' | 'no'
  'h:X-Mailgun-Track-Opens'?: 'yes' | 'no'
}

export interface EmailRecipient {
  id: number
  name: string
  email: string
  locale: string
  school_id: number | null
  school_name: string | null
}

export interface RecipientList {
  [index: string]: RecipientVariables
}

export interface RecipientQuery {
  roles: readonly EmailerRoleOptions[]
  actives: readonly EmailerSubActiveOptions[]
  types: readonly EmailerSubTypeOptions[]
  groups: readonly EmailerSubGroupOptions[]
  wonde: readonly EmailerWondeStatusOptions[]
  countries: readonly string[]
  price_types: readonly EmailerPriceTypeOptions[]
  ignore_marketing: Boolean
}

export const EmailerRoleOptions = ['me', 'users', 'admins', 'teachers', 'superusers'] as const
export type EmailerRoleOptions = ArrayElement<typeof EmailerRoleOptions>

export const EmailerSubActiveOptions = ['active', 'expired'] as const
export type EmailerSubActiveOptions = ArrayElement<typeof EmailerSubActiveOptions>

export const EmailerSubTypeOptions = ['free', 'premium', 'none'] as const
export type EmailerSubTypeOptions = ArrayElement<typeof EmailerSubTypeOptions>

export const EmailerSubGroupOptions = ['edshed', 'litshed'] as const
export type EmailerSubGroupOptions = ArrayElement<typeof EmailerSubGroupOptions>

export const EmailerWondeStatusOptions = ['wondeLinked', 'wondeUnlinked'] as const
export type EmailerWondeStatusOptions = ArrayElement<typeof EmailerWondeStatusOptions>

export const EmailerPriceTypeOptions = ['fixed', 'metered'] as const
export type EmailerPriceTypeOptions = ArrayElement<typeof EmailerPriceTypeOptions>

export interface EmailSentResponse {
  success: boolean
  sent_count: number
}

export interface SendEmailJobParams {
  emailObj: Email,
  user: DeepReadonly<UserModel>
}
