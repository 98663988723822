// Import System requirements
import Vue from 'vue'
import '@babel/polyfill'

// import Raven from 'raven-js'
// import RavenVue from 'raven-js/plugins/vue'

import VueRouter from 'vue-router'

import { sync } from 'vuex-router-sync'
import VeeValidate from 'vee-validate'
import Buefy from 'buefy'
import routes from '@/routes'
import store from '@/store'
import config from '@/config'
import { initApi } from '@/edshed-common/api'

import LookupInput from '@/edshed-common/components/LookupInput.vue'
import MultiLookupInput from '@/edshed-common/components/MultiLookupInput.vue'
import ImageInput from '@/edshed-common/components/ImageInput.vue'
import FileInput from '@/edshed-common/components/FileInput.vue'
import RichTextInput from '@/edshed-common/components/RichTextInput.vue'
import MixedContentInput from '@/edshed-common/components/MixedContentInput.vue'
import FileAttachmentField from '@/edshed-common/components/FileAttachmentField.vue'
import MultiFileAttachmentField from '@/edshed-common/components/MultiFileAttachmentField.vue'
import SmartImage from '@/edshed-common/components/SmartImage.vue'
import { i18n } from '@/plugins/i18n'

import '@/edshed-common/directives'

import '@/styles/common.sass'

// Import Helpers for filters
import { domain, count, prettyDate, pluralize } from '@/filters'

// Import Views - Top level
import AppView from '@/components/App.vue'

console.log(`Starting... (${process.env.NODE_ENV})`)

// if (process.env.NODE_ENV !== 'development') {
//   Raven
//     .config('https://c822756e0f6042bc9de059a0dc9e8dd0@sentry.io/291829')
//     .addPlugin(RavenVue, Vue)
//     .install()
// }

Vue.use(VeeValidate)
// import 'buefy/lib/buefy.css'

Vue.use(Buefy, {
  defaultIconPack: 'mdi'
})

Vue.component('LookupInput', LookupInput)
Vue.component('MultiLookupInput', MultiLookupInput)
Vue.component('ImageInput', ImageInput)
Vue.component('FileInput', FileInput)
Vue.component('RichTextInput', RichTextInput)
Vue.component('MixedContentInput', MixedContentInput)
Vue.component('FileAttachmentField', FileAttachmentField)
Vue.component('MultiFileAttachmentField', MultiFileAttachmentField)
Vue.component('SmartImage', SmartImage)

// @ts-ignore
RichTextInput.StaticURL = window.location.origin

// Import Install and register helper items
Vue.filter('count', count)
Vue.filter('domain', domain)
Vue.filter('prettyDate', prettyDate)
Vue.filter('pluralize', pluralize)

Vue.use(VueRouter)

// Routing logic
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

initApi(config.serverURI, () => {
  router.push('/logout')
}, (env) => {
  store.commit('SET_API_ENV', env)
})

// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  // NOTE: Tried to make superusers work without a school, harder than thought...
  // if (router.app.$store.state.user && router.app.$store.state.user.superuser) {
  //   return next()
  // }

  const tokenCookie = getCookie('_session-token_')
  if (to.name !== 'Logout' && tokenCookie === 'null' && store.state.token && router.app.$store.state.token !== tokenCookie) {
    return next({ name: 'Logout' })
  }

  // getEdshedUser()
  if (to.name !== 'Logout' && to.name !== 'LoadUser' && router.app.$store.state.token && router.app.$store.state.token !== 'null' && (!router.app.$store.state.user || router.app.$store.state.token !== router.app.$store.state.user.token)) {
    return next({ name: 'LoadUser', params: { route: to } } as any)
  }

  // window.console.log('Transition', to)
  if (to.matched.some(record => record.meta.requiresAuth) && (!router.app.$store.state.token || router.app.$store.state.token === 'null')) {
    window.console.log('Not authenticated')
    next({
      path: '/login',
      query: to.query // ,
      // query: { redirect: to.fullPath }
    })
    // window.location.replace('https://www.edshed.com/login')
  } else if (to.matched.some(record => record.meta.requiresAuth) && router.app.$store.state.user && router.app.$store.state.user.school && !router.app.$store.state.user.school.teacher && !router.app.$store.state.user.superuser) {
    next({
      path: '/noaccess' // ,
      // query: { redirect: to.fullPath }
    })
  } else if (to.matched.some(record => record.meta.requiresAuth) && router.app.$store.state.user.email && (!router.app.$store.state.user.email_verified || router.app.$store.state.user.email_verified === 'null')) { // school check
    window.console.log('Not verified')
    window.location.replace('https://www.edshed.com/unverified')
  } else if (to.matched.some(record => record.meta.requiresSchool) && (!router.app.$store.state.user.school || router.app.$store.state.user.school === 'null')) { // school check
    window.console.log('No school')
    if (router.app.$store.state.user.district_id || router.app.$store.state.user.all_schools) {
      next({
        path: '/schools',
        query: to.query // ,
        // query: { redirect: to.fullPath }
      })
    } else {
      next({
        path: '/add-school',
        query: to.query // ,
        // query: { redirect: to.fullPath }
      })
    }
  } else if (to.matched.some(record => record.meta.requiresTeacher) && (!router.app.$store.state.user.school.teacher || router.app.$store.state.user.school.teacher === 'null')) {
    window.console.log('No access')
    next({
      path: '/noaccess' // ,
      // query: { redirect: to.fullPath }
    })
    // window.location.replace("https://www.edshed.com/menu")
  } else if (to.matched.some(record => record.meta.requiresSuperuser) && !router.app.$store.state.user.superuser) {
    window.console.log('No access')
    next({
      path: '/noaccess' // ,
      // query: { redirect: to.fullPath }
    })
    // window.location.replace("https://www.edshed.com/menu")
  } else {
    next()
  }
})

sync(store, router)

function getCookie (name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) {
    return match[2]
  }
}

const tokenCookie = getCookie('_session-token_')

// Check local storage to handle refreshes
if (window.localStorage) {
  let localToken = window.localStorage.getItem('token')
  let refreshSession = false
  if (tokenCookie) {
    if (tokenCookie !== localToken) {
      // reset session!
      localToken = tokenCookie
      refreshSession = true
    }
  }
  store.commit('SET_TOKEN', localToken)

  const localUserString = window.localStorage.getItem('user') || 'null'
  const localUser = JSON.parse(localUserString)
  if (localUser && store.state.user !== localUser) {
    console.log('setting user from storage. Token: ' + window.localStorage.getItem('token'))
    if (!refreshSession) {
      store.commit('SET_USER', localUser)
    }
  }
}

// var tokenCookie = getCookie('_session-token_')

// var userCookie = getCookie('_session-user_')

// if (tokenCookie && userCookie) {
//   // store.commit('SET_USER', JSON.parse(userCookie))
//   // store.commit('SET_TOKEN', tokenCookie)
//   window.localStorage.setItem('user', decodeURIComponent(userCookie))
//   window.localStorage.setItem('token', tokenCookie)
// }
// // Check local storage to handle refreshes
// if (window.localStorage) {
//   var localUserString = window.localStorage.getItem('user') || 'null'
//   var localUser = JSON.parse(localUserString)

//   if (localUser && store.state.user !== localUser) {
//     store.commit('SET_USER', localUser)
//     store.commit('SET_TOKEN', window.localStorage.getItem('token'))
//   }
// }

// function getEdshedUser () {
//   var iframe : HTMLIFrameElement | null = document.getElementById("idFrame") as HTMLIFrameElement
//   if (iframe != null) {
//     document.body.removeChild(iframe)
//   }
//   iframe = document.createElement("iframe")
//   iframe.src = 'https://www.edshed.com/id.html'
//   if (process.env.NODE_ENV === 'development') {
//     iframe.src = 'http://localhost:8081/id.html'
//   }
//   iframe.name = "frame"
//   iframe.id = 'idFrame'
//   document.body.appendChild(iframe)
// }

// window.onmessage = function (e) {
//   if (e.origin === 'http://localhost:8081' || e.origin === 'https://www.edshed.com') {
//     // console.log('------')
//     // console.log('e: ' + e.data)
//     var localUserString = window.localStorage.getItem('user') || 'null'
//     var localUser = JSON.parse(localUserString)
//     // console.log('u: ' + store.state.user)
//     console.log(router.currentRoute)
//     let reload = (localUser !== null || router.currentRoute.path === '/login')
//     if ((e.data && e.data.token && !localUser) || (!e.data && localUser) || (e.data && localUser && e.data.token != localUser.token)) {
//       if (e.data) {
//         // console.log('SETTING')
//         store.commit('SET_USER', e.data)
//         store.commit('SET_TOKEN', e.data.token)
//         window.localStorage.setItem('user', JSON.stringify(e.data))
//         window.localStorage.setItem('token', e.data.token)
//         if (reload) {
//           console.log('go')
//           router.go(0)
//         } else {
//           console.log('push')
//           router.push('/')
//         }
//       } else {
//         store.commit('SET_USER', null)
//         store.commit('SET_TOKEN', null)
//         window.localStorage.removeItem('user')
//         window.localStorage.removeItem('token')
//         router.push('/logout')
//       }
//     } else if (!store.state.user) {
//       if (localUser && store.state.user !== localUser) {
//         store.commit('SET_USER', localUser)
//         store.commit('SET_TOKEN', window.localStorage.getItem('token'))
//         if (router.currentRoute.path === '/login') {
//           router.push('/')
//         }
//       }
//     }
//   }
// }

// eslint-disable-next-line no-new
new Vue({
  el: '#root',
  i18n,
  router,
  store,
  render: h => h(AppView)
})
