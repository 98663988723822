





























import { Vue, Component, Prop } from 'vue-property-decorator'
import { FileAttachment } from '../api'

@Component({ components: {}, props: ['value'] })
export default class FileAttachmentField extends Vue {
  @Prop({ required: true })
  private readonly value!: FileAttachment
}
