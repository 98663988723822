import { encode, decode } from 'base-64'
import { MixedContent } from '../api'
import swears from './profanity'
import { RandomGenerator } from './random'

export { swears }
export { RandomGenerator }

export declare type ArrayElementType<TArray extends ReadonlyArray<any>> = TArray extends ReadonlyArray<infer T> ? T : never

export function assertUnreachable (x: never): never {
  throw new Error(`An unreachable event has occurred: ${x}`)
}

/**
 * Does not account for scunthorpe - USE ONLY FOR GENERATED STRINGS
 */
export function checkStringForProfanity (str: string): boolean {
  try {
    const escaped = unescape(encodeURIComponent(str))
    if (typeof escaped === 'string') {
      for (const swear of swears) {
        // console.log('here')
        // if (Buffer) { // Node server doesn't know btoa
        //   if (Buffer.from(escaped.toLowerCase()).toString('base64').includes(swear)) { return true }
        // } else if (btoa) {
        //   if (btoa(escaped.toLowerCase()).includes(swear)) { return true }
        // }
        // if (encode(escaped.toLowerCase()).includes(swear)) { return true }
        // console.log(escaped.toLowerCase(), decode(swear).toLowerCase())
        if (escaped.toLowerCase().includes(decode(swear).toLowerCase())) { return true }
      }
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

/**
 * For content, with spaces between words, will only look for isolated words included in the profanity list
 */
export function checkContentForProfanity (str: string): boolean {
  try {
    if (typeof str === 'string') {
      const string = str.split(' ')
      for (const word of string) {
        const escaped = unescape(encodeURIComponent(word))
        const cleaned = escaped.replace(/[\W_]+/g, '')
        if (typeof escaped === 'string' && typeof cleaned === 'string') {
          for (const swear of swears) {
            // if (Buffer) { // Node server doesn't know btoa
            //   if (Buffer.from(escaped.toLowerCase()).toString('base64') === swear || Buffer.from(cleaned.toLowerCase()).toString('base64') === swear) { return true }
            // } else if (btoa) {
            //   if (btoa(escaped.toLowerCase()) === swear || btoa(cleaned.toLowerCase()) === swear) { return true }
            // }
            if (encode(escaped.toLowerCase()) === swear || encode(cleaned.toLowerCase()) === swear) { return true }
          }
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

/**
 * Typescript-friendly way to check if a value is within an array of values
 *
 * isOfType(question.type, 'text', 'freetext', ...)
 */
export function isOfType<TType extends string, TAllowedType extends TType> (type: TType, ...allowedTypes: readonly TAllowedType[]): type is TAllowedType {
  return allowedTypes.includes(type as TAllowedType)
}

/**
 * Transform a number into words e.g. one thousand
 * @param n number up to 999-billion - allows negatives
 */
export function numberToWords (n: number) {
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']
  const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen']

  function bil (num: number): string {
    if (num >= 1000000000) {
      return bil(Math.floor(num / 1000000000)) + ' billion ' + mil(num % 1000000000)
    } else {
      return mil(num)
    }
  }

  function mil (num: number): string {
    if (num >= 1000000) {
      return mil(Math.floor(num / 1000000)) + ' million ' + tho(num % 1000000)
    } else {
      return tho(num)
    }
  }

  function tho (num: number) {
    if (num >= 1000) {
      return hun(Math.floor(num / 1000)) + ' thousand ' + hun(num % 1000)
    } else {
      return hun(num)
    }
  }

  function hun (num: number) {
    if (num > 99) {
      return ones[Math.floor(num / 100)] + ' hundred ' + ten(num % 100)
    } else {
      return ten(num)
    }
  }

  function ten (num: number) {
    if (num < 10) { return ones[num] } else if (num >= 10 && num < 20) { return teens[num - 10] } else {
      return tens[Math.floor(num / 10)] + ' ' + ones[num % 10]
    }
  }

  const num = Math.floor(Math.abs(n))
  const decimals = n.toString().split('.')[1]
  let decimalText = ''
  if (decimals) {
    decimalText = ' point'
    for (const d of decimals.split('')) {
      decimalText += ` ${ones[parseInt(d)] || 'zero'}`
    }
  }

  if (num === 0) {
    return decimalText ? `zero${decimalText}` : 'zero'
  } else {
    return n < 0 ? `minus ${bil(num)}${decimalText}` : `${bil(num)}${decimalText}`
  }
}

export function numberToRoman (n: number) {
  // https://www.w3resource.com/javascript-exercises/javascript-math-exercise-21.php

  const num = Math.floor(n)
  if (typeof num !== 'number') { return `${n}` }

  const digits = String(+num).split('') || ['']
  const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
    '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
    '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX']
  let roman = ''
  let i = 3
  while (i--) { roman = (key[+digits.pop()! + (i * 10)] || '') + roman }
  return Array(+digits.join('') + 1).join('M') + roman
}

/** For now only compares "text" content type */
export function isAnswerEquivalent (answers: readonly MixedContent[], givenAnswer: MixedContent, case_sensitive?: boolean) {
  if (case_sensitive) {
    return answers.some(answer => (answer.value || '').trim() === (givenAnswer.value || '').trim())
  }
  return answers.some(answer => (answer.value || '').toLowerCase().trim() === (givenAnswer.value || '').toLowerCase().trim())
}

export function keys<T> (obj: T) {
  return Object.keys(obj) as unknown as readonly (Extract<keyof T, string>)[]
}

export function numericKeys<T> (obj: T) {
  return Object.keys(obj).map(i => parseInt(i, 10)) as unknown as readonly (Extract<keyof T, number>)[]
}

export function fromEntries<T = any> (entries: (readonly [PropertyKey, T])[]): { [k: string]: T } {
  const obj: any = {}
  for (const [k, v] of entries) {
    obj[k] = v
  }
  return obj
}

export function createCookie (token: string, hostname: string, expiry: Date) {
  let domain: string

  if (hostname === 'localhost') {
    domain = 'localhost'
  } else {
    // e.g. www.edshed.com becomes edshed.com
    const parts = hostname.split('.')
    domain = parts.slice(-2).join('.')
  }

  return `_session-token_=${token};path=/;domain=${domain};expires=${expiry.toUTCString()};`
}

export function sleep (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function isMasteryZonePreviewSchool (schoolId: number): boolean {
  const previewSchools = [
    8, // EdShed School
    1665, // Bewdley Primary School
    3477, // The Bewdley School (Secondary)
    60509 // Carys' Test School
  ]
  return previewSchools.includes(schoolId)
}