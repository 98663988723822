export interface PhonicsGameInfo {
  id: number
  ident: string // for saving to Quiz Shed engine
  title: string
  description: string
  type: 'toybox' | 'sounds' | 'vocabulary' | 'other' | 'assessment',
  graphemes: number[] // grapheme ids accepted by the game - leave empty for no graphemes - include -1 to include all graphemes
  params?: any
  difficultyLevels: number[] | null
  assignable: boolean
  hidden: boolean // hide from menus if true
  resultType: 'questions' | 'time'
  freeplay: boolean
}

function makeEnumerableArray<T extends string> (...a: Array<PhonicsGameInfo & { ident: T }>) {
  return a
}

const _phonicsGames = makeEnumerableArray(
  {
    id: 1,
    ident: 'p-p',
    title: 'Keyboard',
    description: 'Play music with an interactive keyboard.',
    type: 'toybox',
    graphemes: [],
    params: { game: 'piano' },
    difficultyLevels: null,
    assignable: true,
    hidden: false,
    resultType: 'time',
    freeplay: true
  },
  {
    id: 2,
    ident: 'p-so-f',
    title: 'Word Sounds',
    description: 'Explore the sounds in different words.',
    type: 'toybox',
    graphemes: [-1],
    params: { game: 'sounding-out', mode: 'free' },
    difficultyLevels: null,
    assignable: true,
    hidden: false,
    resultType: 'time',
    freeplay: true
  },
  {
    id: 3,
    ident: 'p-ss-g',
    title: 'Phonics Shed Garden',
    description: 'Explore the sounds you can find outdoors.',
    type: 'toybox',
    graphemes: [],
    params: { game: 'scene-sounds', scene: 'garden' },
    difficultyLevels: null,
    assignable: true,
    hidden: false,
    resultType: 'time',
    freeplay: true
  },
  {
    id: 4,
    ident: 'p-sr',
    title: 'Sound Recorder',
    description: 'Record and listen to your own voice.',
    type: 'toybox',
    graphemes: [],
    params: { game: 'sound-recorder' },
    difficultyLevels: null,
    assignable: false,
    hidden: false,
    resultType: 'time',
    freeplay: true
  },
  {
    id: 5,
    ident: 'p-so-1',
    title: 'First Sounds',
    description: 'Do you know the first sounds of these words?',
    type: 'sounds',
    graphemes: [-1],
    params: { game: 'sounding-out', mode: 'first' },
    difficultyLevels: [1, 2],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 6,
    ident: 'p-so-n',
    title: 'Last Sounds',
    description: 'Do you know the last sounds of these words?',
    type: 'sounds',
    graphemes: [-1],
    params: { game: 'sounding-out', mode: 'last' },
    difficultyLevels: [1, 2],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 7,
    ident: 'p-so-r',
    title: 'Sounding Out',
    description: 'Do you know all of the sounds of these words?',
    type: 'sounds',
    graphemes: [-1],
    params: { game: 'sounding-out', mode: 'random' },
    difficultyLevels: [1, 2],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 8,
    ident: 'p-bp-f',
    title: 'Joe\'s Body Percussion',
    description: 'Explore body percussion with Joe.',
    type: 'toybox',
    params: { game: 'body-percussion', mode: 'free' },
    graphemes: [],
    difficultyLevels: null,
    assignable: true,
    hidden: false,
    resultType: 'time',
    freeplay: true
  },
  {
    id: 9,
    ident: 'p-a',
    title: 'Grapheme Assessment',
    description: 'Test and assess the ability to sound out graphemes.',
    type: 'assessment',
    params: { game: 'assessment' },
    graphemes: [],
    difficultyLevels: null,
    assignable: false,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 10,
    ident: 'p-a-1',
    title: 'Phonics Screening Check Simulator ',
    description: 'Test and assess the ability to sound out words in the style of the phonics screening check.',
    type: 'assessment',
    params: { game: 'assessment', is_screening_check: true },
    graphemes: [],
    difficultyLevels: null,
    assignable: false,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 11,
    ident: 'p-dh-p',
    title: 'Dare in the Air',
    description: 'Fly to collect graphemes and construct words.',
    type: 'vocabulary',
    params: { game: 'dare-hare', mode: 'phonics' },
    graphemes: [-1],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 12,
    ident: 'p-dh-s',
    title: 'Dare in the Air (Spelling)',
    description: 'Fly to collect letters and construct words.',
    type: 'vocabulary',
    params: { game: 'dare-hare', mode: 'spelling' },
    graphemes: [-1],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 13,
    ident: 'p-cm-p',
    title: 'Match Phonemes',
    description: 'Flip cards and match the phonemes',
    type: 'sounds',
    params: { game: 'card-match', mode: 'phonemes' },
    graphemes: [-1],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: true,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 14,
    ident: 'p-cm-gp',
    title: 'Match Graphemes',
    description: 'Flip cards and match the graphemes to the phonemes',
    type: 'sounds',
    params: { game: 'card-match', mode: 'grapheme-phoneme' },
    graphemes: [-1],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 15,
    ident: 'p-cm-w',
    title: 'Match Words',
    description: 'Flip cards and match the words',
    type: 'vocabulary',
    params: { game: 'card-match', mode: 'words' },
    graphemes: [-1],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 16,
    ident: 'p-cm-c',
    title: 'Match Characters',
    description: 'Flip cards and match the characters',
    type: 'toybox',
    params: { game: 'card-match', mode: 'characters' },
    graphemes: [],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 17,
    ident: 'p-ip',
    title: 'Indy Pop',
    description: 'Pop balloons which make the same sound',
    type: 'sounds',
    params: { game: 'balloon-pop' },
    graphemes: [-1],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: false,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 18,
    ident: 'p-bp',
    title: 'Joe\'s Body Percussion (Challenge)',
    description: 'Explore body percussion with Joe and copy along.',
    type: 'toybox',
    params: { game: 'body-percussion', mode: 'game' },
    graphemes: [],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: true,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 19,
    ident: 'p-cm-g',
    title: 'Match Graphemes',
    description: 'Flip cards and match the graphemes',
    type: 'sounds',
    params: { game: 'card-match', mode: 'graphemes' },
    graphemes: [-1],
    difficultyLevels: [1, 2, 3],
    assignable: true,
    hidden: true,
    resultType: 'questions',
    freeplay: false
  },
  {
    id: 20,
    ident: 'p-sb',
    title: 'Soundboard',
    description: 'Play sounds associated with images',
    type: 'toybox',
    graphemes: [],
    params: { game: 'soundboard' },
    difficultyLevels: null,
    assignable: true,
    hidden: false,
    resultType: 'time',
    freeplay: true
  }
)

export const phonicsGames = _phonicsGames as PhonicsGameInfo[]

export const PhonicsGameIdent = _phonicsGames.map(g => g.ident)
export type PhonicsGameIdent = typeof _phonicsGames[number]['ident']

export function getPhonicsGameByIdent (ident: string) {
  const game = phonicsGames.find(g => g.ident === ident)

  if (game) {
    return game
  }

  throw new Error(`Game ident ${ident} not recognised`)
}
