








































import { Component, Mixins } from 'vue-property-decorator'
import { assertUnreachable } from '../../edshed-common/utils'
import { MixedContent } from '../api'

@Component({ props: ['value', 'placeholder', 'height', 'plainText', 'html'] })
export default class MixedContentInput extends Mixins() {
  private value?: MixedContent

  public mounted () {
    if (!this.value) {
      const newValue: MixedContent = {
        type: 'html',
        value: ''
      }
      this.triggerChange(newValue)
      return
    }
    if (this.$props.html) {
      this.value.type = 'html'
    }
  }

  get showTypeSelector () {
    if (this.$props.plainText) {
      return this.value && this.value.type !== 'text'
    }
    if (this.$props.html) {
      return this.value && this.value.type !== 'html'
    }
    return true
  }

  private onContentTypeChanged (type: MixedContent['type']) {
    if (type === 'text') {
      this.triggerChange({
        type,
        value: this.value ? this.value.value : ''
      })
    } else if (type === 'html') {
      this.triggerChange({
        type,
        value: this.value ? this.value.value : ''
      })
    } else if (type === 'equation') {
      this.triggerChange({
        type,
        value: ''
      })
    } else if (type === 'objects') {
      this.triggerChange({
        type,
        value: ''
      })
    } else {
      assertUnreachable(type)
    }
  }

  private triggerChange (type: MixedContent) {
    this.$emit('input', type)
  }
}
