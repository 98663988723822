const DashView = () => import('@/components/Dash')
const LoginView = () => import('@/components/Login')
const LoginWondeView = () => import('@/components/LoginWonde')
const LoginCleverView = () => import('@/components/LoginClever')
const NotFoundView = () => import('@/components/404')
const VerifyView = () => import('@/components/Verify')
const PasswordResetView = () => import('@/components/PasswordReset')
const UnsubscribeView = () => import('@/components/Unsubscribe')
const UnverifiedView = () => import('@/components/Unverified')
const LogoutView = () => import('@/components/Logout')
const RegisterView = () => import('@/components/Register')
const ForgottenView = () => import('@/components/Forgotten')
const AddSchoolView = () => import('@/components/AddSchool')
const DistrictView = () => import('@/components/District')
const NoAccessView = () => import('@/components/NoAccess')
const TeacherInviteView = () => import('@/components/views/TeacherInvite')

// Import Views - Dash
const DashboardView = () => import('@/components/views/Dashboard')
const ListsView = () => import('@/components/views/Lists')
const ListView = () => import('@/components/views/List')
const UserView = () => import('@/components/views/UserLists')
const SearchLists = () => import('@/components/views/SearchLists')
const AccountView = () => import('@/components/views/Account')
const SchoolView = () => import('@/components/views/School')
const TeachersView = () => import('@/components/views/Teachers')
const GroupsView = () => import('@/components/views/Groups')
const GroupView = () => import('@/components/views/Group')
const PupilsView = () => import('@/components/views/Pupils')
const PupilView = () => import('@/components/views/Pupil')
const WritingPieceView = () => import('@/components/views/WritingPiece')
const WritingPieceImportView = () => import('@/components/views/WritingPieceImport')
const SchemeView = () => import('@/components/views/Scheme')
const MathsSchemeView = () => import('@/components/views/MathsScheme')
const HomeworkView = () => import('@/components/views/Homework')
const HomeworkDetailView = () => import('@/components/views/HomeworkDetail')
const HivesView = () => import('@/components/views/Hives')
const HiveDetails = () => import('@/components/views/HiveDetails')
const SchoolsView = () => import('@/components/views/Schools')
const InvoicesView = () => import('@/components/views/Invoices')
const TrialsView = () => import('@/components/views/Trials')
const MerchSalesView = () => import('@/components/views/MerchSales')
const MerchProductsView = () => import('@/components/views/MerchProducts')
const FinancialsView = () => import('@/components/views/Financials')
const EmailsView = () => import('@/components/views/Emails')
const AddEmailView = () => import('@/components/views/components/AddEmail')
const UsersView = () => import('@/components/views/Users')
const ResourcesView = () => import('@/components/views/resources/Resources')
const ExternalCurriculaView = () => import('@/components/views/external/Curricula')
const ExternalCurriculumNodesView = () => import('@/components/views/external/CurriculumNodes')
const PacksView = () => import('@/components/views/quiz/Packs')
const CurriculaView = () => import('@/components/views/quiz/Curricula')
const CurriculumNodeView = () => import('@/components/views/quiz/CurriculumNodes')
const DistrictsView = () => import('@/components/views/Districts')
const ResourceView = () => import('@/components/views/resources/Resource')
const LoadUser = () => import('@/components/views/LoadUser')
const DictionaryView = () => import('@/components/views/Dictionary')
const LetterTilesWordsView = () => import('@/components/views/LetterTilesWords')
const EventsView = () => import('@/components/views/Events')
const XeroSettings = () => import('@/components/views/XeroSettings')
const ReportedQuestions = () => import('@/components/views/ReportedQuestions')
const FeaturedQuizzes = () => import('@/components/views/FeaturedQuizzes')
const QuizSubjects = () => import('@/components/views/QuizSubjects')
const PhonicsScreening = () => import('@/components/views/PhonicsScreening')
const Phonemes = () => import('@/components/views/Phonemes')
const EngagementsView = () => import('@/components/views/Engagements')
const CancellationsView = () => import('@/components/views/Cancellations')
const SalesDashboardView = () => import('@/components/views/SalesDashboard')
const GrammarArcade = () => import('@/components/views/GrammarArcade')
const CountriesView = () => import('@/components/views/Countries')
const SubscriptionProductsView = () => import('@/components/SubscriptionProducts')
const SubscriptionProductView = () => import('@/components/SubscriptionProduct')
const SubscriptionActionsView = () => import('@/components/SubscriptionActions')

// Routes
const routes = [
  {
    path: '/loaduser',
    name: 'LoadUser',
    component: LoadUser
  },
  {
    path: '/noaccess',
    component: NoAccessView,
    name: 'No Access'
  },
  {
    path: '/unsubscribe',
    component: UnsubscribeView,
    name: 'Unsubscribe from updates'
  },
  {
    path: '/verify',
    component: VerifyView,
    name: 'Verifying email...'
  },
  {
    path: '/passwordreset',
    component: PasswordResetView,
    name: 'Reset Password'
  },
  {
    path: '/unverified',
    component: UnverifiedView,
    name: 'Email Unverified',
    meta: { description: 'User email unverified' }
  },
  {
    path: '/loginwonde',
    component: LoginWondeView,
    name: 'LoginWonde'
  },
  {
    path: '/loginclever',
    component: LoginCleverView,
    name: 'LoginClever'
  },
  {
    path: '/register',
    component: RegisterView,
    name: 'Create Account'
  },
  {
    path: '/logout',
    component: LogoutView,
    name: 'Logout',
    meta: { description: 'Logout of app', requiresAuth: false }
  },
  {
    path: '/add-school',
    component: AddSchoolView,
    name: 'Add School',
    meta: { description: 'Add your school', requiresAuth: true }
  },
  {
    path: '/teacherinvite',
    component: TeacherInviteView,
    name: 'Teacher Invite',
    meta: { description: 'Invitation to join a school' }
  },
  {
    path: '/',
    component: DashView,
    children: [
      {
        path: '/schools',
        component: DistrictView,
        name: 'District',
        meta: { description: 'Manage District', requiresAuth: true }
      },
      {
        path: '/resources/:id',
        component: ResourceView,
        name: 'Resource'
      },
      {
        path: '/login',
        component: LoginView,
        name: 'Sign In'
      },
      {
        path: '/forgotten',
        component: ForgottenView,
        name: 'Forgotten Password',
        meta: { description: 'Reset your password' }
      },
      {
        path: 'dashboard',
        alias: '',
        component: DashboardView,
        name: 'Dashboard',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Overview of system' }
      }, {
        path: 'scheme',
        component: SchemeView,
        name: 'Scheme',
        meta: { description: 'Our Whole-School Spelling Scheme' }
      }, {
        path: 'curriculum',
        component: SchemeView,
        name: 'Curriculum',
        meta: { description: 'Our Whole-School Spelling Curriculum' }
      }, {
        path: 'mathsresources',
        component: MathsSchemeView,
        name: 'Maths Resources',
        meta: { description: 'Maths Shed Resources' }
      }, {
        path: 'mathresources',
        component: MathsSchemeView,
        name: 'Maths Resources',
        meta: { description: 'Math Shed Resources' }
      }, {
        path: 'lists',
        component: ListsView,
        name: 'Lists',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Your spelling lists' }
      }, {
        path: 'list/:ident',
        component: ListView,
        name: 'List Details',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Spelling Shed list details' }
      }, {
        path: 'users/:ident',
        component: UserView,
        name: 'User',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'User\'s Lists' }
      }, {
        path: 'search-lists',
        component: SearchLists,
        name: 'Search Lists',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Search for lists' }
      }, {
        path: 'account',
        component: AccountView,
        name: 'Account',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage your account details' }
      }, {
        path: 'school',
        component: SchoolView,
        name: 'School',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage your school\'s details' }
      }, {
        path: 'teachers',
        component: TeachersView,
        name: 'Teachers',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage the teachers in your school' }
      }, {
        path: 'groups',
        component: GroupsView,
        name: 'Pupil Groups',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage the pupil groups in your school' }
      }, {
        path: 'groups/:id',
        component: GroupView,
        name: 'Group Details',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage your group' }
      }, {
        path: 'pupils',
        component: PupilsView,
        name: 'Pupils',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage your pupils' }
      }, {
        path: 'students',
        component: PupilsView,
        name: 'Students',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage your students' }
      }, {
        path: 'pupils/:id',
        component: PupilView,
        name: 'Pupil Details',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage your pupil' }
      }, {
        path: 'pupils/:pupil_id/writing_pieces/view/:piece_id',
        component: WritingPieceView,
        name: 'Worksheet',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Writing Piece View' }
      }, {
        path: 'pupils/:pupil_id/writing_pieces/import/:piece_id',
        component: WritingPieceImportView,
        name: 'Worksheet Import',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Writing Piece Import' }
      }, {
        path: 'students/:id',
        component: PupilView,
        name: 'Student Details',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Manage your student' }
      }, {
        path: 'homework',
        component: HomeworkView,
        name: 'Assignments',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Assignments you created' }
      }, {
        path: 'homework/:id',
        component: HomeworkDetailView,
        name: 'Assignment Details',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'The details for an assignment' }
      }, {
        path: 'hives',
        component: HivesView,
        name: 'Hive Games',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Your Hive Games' }
      }, {
        path: 'hives/:id',
        component: HiveDetails,
        name: 'Hive Game',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'The details for a hive game' }
      },
      {
        path: 'subscriptions',
        component: SchoolsView,
        name: 'Schools',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Schools' }
      }, {
        path: 'invoices',
        component: InvoicesView,
        name: 'Invoices',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Invoices' }
      }, {
        path: 'trials',
        component: TrialsView,
        name: 'Trials',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Trials' }
      }, {
        path: 'merchsales',
        component: MerchSalesView,
        name: 'Merch Sales',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Merch Sales' }
      }, {
        path: 'merch-products',
        component: MerchProductsView,
        name: 'Merch Products',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Merch Products' }
      }, {
        path: 'financials',
        component: FinancialsView,
        name: 'Financials',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Financials' }
      }, {
        path: 'dictionary',
        component: DictionaryView,
        name: 'Dictionary',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Dictionary' }
      }, {
        path: 'letter_tiles_words',
        component: LetterTilesWordsView,
        name: 'LetterTilesWords',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Letter Tiles Words' }
      }, {
        path: 'districts',
        component: DistrictsView,
        name: 'Districts',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Districts' }
      }, {
        path: 'emails',
        component: EmailsView,
        name: 'Emails',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Emails' }
      },
      {
        path: 'emails/add',
        component: AddEmailView,
        name: 'Add Email',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Add Email' }
      },
      {
        path: 'emails/:id',
        component: AddEmailView,
        name: 'Emails',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Emails' }
      },
      {
        path: 'users',
        component: UsersView,
        name: 'Users',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Users' }
      }, {
        path: 'resources',
        component: ResourcesView,
        name: 'Resources',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Resources' }
      }, {
        path: 'external_curricula',
        component: ExternalCurriculaView,
        name: 'External Curricula',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'External Curricula' }
      }, {
        path: 'external_curriculum_nodes',
        component: ExternalCurriculumNodesView,
        name: 'External Curriculum Nodes',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'External Curriculum Nodes' }
      }, {
        path: 'packs',
        component: PacksView,
        name: 'Packs',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Packs' }
      }, {
        path: 'curricula',
        component: CurriculaView,
        name: 'Curricula',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Curricula' }
      }, {
        path: 'curriculum_nodes',
        component: CurriculumNodeView,
        name: 'Curriculum Nodes',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Curriculum Nodes' }
      }, {
        path: 'event_logs',
        component: EventsView,
        name: 'Events Logs',
        meta: { requiresAuth: true, requiresSchool: true, requiresSubscription: true, requiresTeacher: true, description: 'Event Logs' }
      },
      {
        path: 'xero-settings',
        component: XeroSettings,
        name: 'Xero Settings',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'reported_questions',
        component: ReportedQuestions,
        name: 'Reported Content',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'featured_quizzes',
        component: FeaturedQuizzes,
        name: 'Featured Quizzes',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'quiz_subjects',
        component: QuizSubjects,
        name: 'Quiz Subjects',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'phonics_screening',
        component: PhonicsScreening,
        name: 'Phonics Screening',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'phonemes',
        component: Phonemes,
        name: 'Phonemes',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'engagement_reports',
        component: EngagementsView,
        name: 'Engagement Reports',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'cancellations',
        component: CancellationsView,
        name: 'Cancellations',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'sales_dashboard',
        component: SalesDashboardView,
        name: 'Sales Dashboard',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'countries',
        component: CountriesView,
        name: 'Countries',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'subscription-actions',
        component: SubscriptionActionsView,
        name: 'Subscription Actions',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'subscription-products',
        component: SubscriptionProductsView,
        name: 'Subscription Products',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'subscription-products/:id',
        component: SubscriptionProductView,
        name: 'Subscription Product',
        meta: { requiresAuth: true, requiresSuperuser: true }
      },
      {
        path: 'grammar',
        component: GrammarArcade,
        name: 'Grammar Arcade',
        meta: { requiresAuth: true, requiresSuperuser: true }
      }
    ]
  }, {
    // not found handler
    path: '*',
    component: NotFoundView
  }
]

export default routes
