






/* eslint-disable no-undef */
import { Mixins, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class EquationInput extends Mixins() {
  @Prop({ required: true })
  private readonly value!: string

  private last = ''

  private guppy!: Guppy

  public mounted () {
    if ((process as any).client || (process as any).browser) {
      this.guppy = new Guppy(this.$refs.guppyInput as Element, {})

      this.guppy.activate()

      this.last = this.value

      this.import(this.value)

      this.$watch(() => this.value, () => {
        if (this.value === this.last) { return }

        this.import(this.value)
      })

      this.guppy.event('change', () => {
        try {
          const equation = this.guppy.engine.get_content('text')

          if (equation === this.last) { return }

          this.last = equation
          this.$emit('input', equation)
        } catch { }
      })
    }
  }

  private import (equation: string) {
    try {
      this.guppy.import_text(equation)
      this.guppy.render(true)
    } catch (err) {
      // console.error('Error parsing equation:', equation, err)
    }
  }
}
