


















import { Vue, Component, Prop } from 'vue-property-decorator'
import EquationInput from './EquationInput.vue'

@Component({ components: { EquationInput } })
export default class EquationModal extends Vue {
  @Prop({ required: true })
  private value!: string

  private equation = ''

  public mounted () {
    this.equation = this.value
  }
}
