import _ from 'lodash'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { striptags } from '../utils/striptags'

@Component
export default class ComponentHelperBase extends Vue {
  /** Renamed from `locale` due to conflict */
  /** Uncomment when locales are global */
  // protected get siteLocale () {
  //   return this.$i18n.locale
  // }

  // protected pageUrl (pagePath: string) {
  //   if (!pagePath) {
  //     return `/${this.siteLocale}`
  //   }

  //   return `/${this.siteLocale}/${pagePath}`
  // }

  protected getHashParam (name: string, def?: string): string | undefined {
    if (!this.$route.hash) { return def }

    const hashParams = new URLSearchParams(this.$route.hash.substring(1))
    const value = hashParams.get(name)

    if (!value) { return def }

    return value
  }

  protected setHashParam (name: string, value: string | undefined) {
    const hash = this.$route.hash || '#'
    const hashParams: any = new URLSearchParams(hash.substring(1))

    if (value) {
      hashParams.set(name, value)
    } else {
      hashParams.delete(name)
    }

    const originalUrl = this.$route.fullPath.split('#')[0]

    this.$router.replace(originalUrl + ([...hashParams.keys()].length ? '#' + hashParams.toString() : ''))
  }

  /** Alternative for this.$route.query.param_name */
  protected numberParam<TRequired extends boolean> (name: string, required: TRequired): number | (TRequired extends false ? undefined : never) {
    const param = this.$route.query[name]

    if (Array.isArray(param)) {
      throw new TypeError(`Number parameter "${name}" is invalid type`)
    }

    if (required && typeof param !== 'string') {
      throw new TypeError(`Missing number parameter "${name}"`)
    }

    return param ? parseInt(param, 10) : undefined!
  }

  protected humanise (input: string) {
    return _.startCase(input)
  }

  protected stripHtml (input: string) {
    // const pattern = /<[a-z/]+>/g
    // return input.replace(pattern, '')
    return striptags(input)
  }

  protected truncate (input: string, length = 50) {
    if (input.length <= length) { return input }

    return input.slice(0, length).trim() + '…'
  }

  protected ellipsis (input: string, length = 50) {
    if (!input) { return '' }
    if (input.length <= length) { return input }

    return input.slice(0, length / 2).trim() + ' … ' + input.slice(-length / 2)
  }

  protected shuffleArray (array: Array<any>) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  protected formatDuration (seconds: number) {
    const duration = moment.duration(seconds, 'seconds')

    if (seconds < 60) {
      return `${duration.seconds()}s`
    } else if (seconds >= 60 && seconds < 3600) {
      return `${duration.minutes()}m ${duration.seconds()}s`
    } else if (seconds >= 3600 && seconds < 86400) {
      return `${duration.hours()}h ${duration.minutes()}m`
    } else {
      return `${duration.days()}d`
    }
  }

  protected ordinalSuffixOf (number: number) {
    const x = number % 10
    const y = number % 100
    if (x === 1 && y !== 11) {
      return number + 'st'
    }
    if (x === 2 && y !== 12) {
      return number + 'nd'
    }
    if (x === 3 && y !== 13) {
      return number + 'rd'
    }
    return number + 'th'
  }

  // Sassoon has regional versions - this returns the classname to give an element the correct font
  protected getSassoonClassNameForLocale (locale: string, curly?: boolean) {
    const l = locale || 'en-gb'
    let variant = ''
    switch (l) {
      case 'en-us':
        variant = '-us'
        break
      case 'en-za':
        variant = '-za'
        break
      default:
        variant = ''
        break
    }
    return `font-is-sassoon${variant}${curly && !variant ? '-curly' : ''}`
  }

  protected getSassoonFontForLocale (locale: string) {
    const l = locale || 'en-gb'
    switch (l) {
      case 'en-us':
        return 'SassoonUs'
      case 'en-za':
        return 'SassoonZa'
      default:
        return 'Sassoon'
    }
  }

  // protected dateFormat (dateString: string) {
  //   return moment(dateString).format('LLLL')
  // }

  protected range (start: number, end: number) {
    return Array(end - start + 1).fill(0).map((_, idx) => start + idx)
  }

  // postpend an s on the end if the quantity is not equal to 1
  public pluralise (quantity: number, plural = 's') {
    return quantity === 1 ? '' : plural
  }

  public joinWithCommasAndAnd (strings: string[]) {
    if (strings.length === 0) {
      return ''
    } else if (strings.length === 1) {
      return strings[0]
    } else {
      return strings.slice(0, -1).join(', ') + ' and ' + strings[strings.length - 1]
    }
  }
}
