import { FlagImages } from './flags'

export interface LocaleAudio {
  /** Where to generate data from */
  source?: 'Google' | 'Amazon'

  /** Which Locale to fall back to when not available */
  locale?: Locale
}

interface _LocaleData {
  name: string
  languageName: string | null
  emojiCode?: string
  language: string
  country: string
  spelling: boolean
  quiz: boolean
  user: boolean
  school: boolean
  currency: 'gbp' | 'usd' | 'eur' | 'nzd' | 'aud' | 'zar'
  stripe?: 'GB' | 'US'
  svg?: keyof typeof FlagImages
}

export class LocaleData {
  /** Name of the country being represented by locale */
  name: string

  /** Name of language being represented by locale */
  languageName: string | null

  /** Key of flag SVG for locale switcher. See edshed-common/i18n/flags/index.ts */
  svg: keyof typeof FlagImages | null

  /** Emoji code for locale flag. Generated from country code */
  emoji: string

  /** ISO standard format: lower case language - dash - upper case country e/g. "en-GB". Generated from language and country fields. */
  iso: string

  /** i18n format: lower case language - dash - lower case country e.g. "en-gb". Generated from language and country fields. */
  code: string

  /** Our database format: lower case language - underscore - upper case country e.g. "en_GB". Generated from language and country fields. */
  underscored: Locale

  /** ISO 639-1 language code (2 characters) i.e. en */
  language: string

  /** ISO 3166-1 alpha-2 country code (2 characters) i.e GB */
  country: string

  /** Indicates whether locale can be used for spelling lists */
  spelling: boolean

  /** Indicates whether locale can be used for quiz entities */
  quiz: boolean

  /** Indicates whether locale can be used for user accounts */
  user: boolean

  /** Indicates whether locale can be used for schools */
  school: boolean

  /** Default currency for locale */
  currency: 'gbp' | 'usd' | 'eur' | 'nzd' | 'aud' | 'zar'

  /** Stripe account to use for locale */
  stripe: 'GB' | 'US'

  constructor (data: _LocaleData) {
    this.name = data.name
    this.languageName = data.languageName
    this.language = data.language.toLowerCase()
    this.country = data.country.toUpperCase()
    this.spelling = data.spelling
    this.quiz = data.quiz
    this.user = data.user
    this.school = data.school
    this.currency = data.currency
    this.stripe = data.stripe || 'GB'
    this.svg = data.svg || null
    this.iso = `${data.language.toLowerCase()}-${data.country.toUpperCase()}`
    this.code = `${data.language.toLowerCase()}-${data.country.toLowerCase()}`
    this.underscored = `${data.language.toLowerCase()}_${data.country.toUpperCase()}` as Locale

    if (data.emojiCode) {
      this.emoji = data.emojiCode
    } else {
      const aFlagInDec = 127462
      const countryLetters = (data.emojiCode || this.country).split('')
      const firstEmoji = String.fromCodePoint(aFlagInDec + countryLetters[0].charCodeAt(0) - 'A'.charCodeAt(0))
      const secondEmoji = String.fromCodePoint(aFlagInDec + countryLetters[1].charCodeAt(0) - 'A'.charCodeAt(0))
      this.emoji = `${firstEmoji}${secondEmoji}`
    }
  }
}

// some locales are not available in Google TTS
// see https://cloud.google.com/text-to-speech/docs/voices for current list
export const audioOverrides: ReadonlyMap<Locale, LocaleAudio> = new Map([
  ['en_CA', { locale: 'en_US' }],
  ['en_HK', { locale: 'en_GB' }],
  ['en_IE', { locale: 'en_GB' }],
  ['en_PK', { locale: 'en_IN' }],
  ['en_NZ', { locale: 'en_AU' }],
  ['cy_GB', { source: 'Amazon' }],
  ['en_ZA', { source: 'Amazon' }]
])

/** Map of usable locales - add to this to create more locales */
const _localeMap = [
  ['en_GB', new LocaleData({ name: 'United Kingdom', languageName: 'British English', language: 'en', country: 'GB', spelling: true, quiz: true, user: true, school: true, currency: 'gbp', svg: 'gb' })],
  ['en_AU', new LocaleData({ name: 'Australia', languageName: null, language: 'en', country: 'AU', spelling: false, quiz: true, user: true, school: true, currency: 'aud', svg: 'au' })],
  ['en_CA', new LocaleData({ name: 'Canada', languageName: null, language: 'en', country: 'CA', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'ca' })], // disabled until better currency support
  ['en_HK', new LocaleData({ name: 'China (Hong Kong)', languageName: null, language: 'en', country: 'HK', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'hk' })], // disabled until better currency support
  ['en_IE', new LocaleData({ name: 'Ireland', languageName: null, language: 'en', country: 'IE', spelling: false, quiz: true, user: true, school: true, currency: 'eur', svg: 'ie' })],
  ['en_IN', new LocaleData({ name: 'India', languageName: null, language: 'en', country: 'IN', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'in' })], // disabled until better currency support
  ['en_NZ', new LocaleData({ name: 'New Zealand', languageName: null, language: 'en', country: 'NZ', spelling: false, quiz: true, user: true, school: true, currency: 'nzd', svg: 'nz' })],
  ['en_PK', new LocaleData({ name: 'Pakistan', languageName: null, language: 'en', country: 'PK', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'pk' })], // disabled until better currency support
  ['en_US', new LocaleData({ name: 'United States', languageName: 'American English', language: 'en', country: 'US', spelling: true, quiz: true, user: true, school: true, currency: 'usd', svg: 'us', stripe: 'US' })],
  ['en_ZA', new LocaleData({ name: 'South Africa', languageName: null, language: 'en', country: 'ZA', spelling: false, quiz: true, user: true, school: true, currency: 'zar', svg: 'za', stripe: 'GB' })],
  ['fr_FR', new LocaleData({ name: 'France', languageName: 'French', language: 'fr', country: 'FR', spelling: true, quiz: false, user: false, school: false, currency: 'eur' })],
  ['de_DE', new LocaleData({ name: 'Germany', languageName: 'German', language: 'de', country: 'DE', spelling: true, quiz: false, user: false, school: false, currency: 'eur' })],
  ['es_ES', new LocaleData({ name: 'Spain', languageName: 'Spanish', language: 'es', country: 'ES', spelling: true, quiz: false, user: false, school: false, currency: 'eur' })],
  ['cy_GB', new LocaleData({ name: 'Wales', languageName: 'Welsh', language: 'cy', country: 'GB', spelling: true, quiz: false, user: false, school: false, currency: 'gbp', emojiCode: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' })],
  ['pt_PT', new LocaleData({ name: 'Portugal', languageName: 'Portuguese (Portugal)', language: 'pt', country: 'PT', spelling: true, quiz: false, user: false, school: false, currency: 'eur' })],
  ['pt_BR', new LocaleData({ name: 'Brazil', languageName: 'Portuguese (Brazil)', language: 'pt', country: 'BR', spelling: true, quiz: false, user: false, school: false, currency: 'usd' })],
  ['af_ZA', new LocaleData({ name: 'Suid Afrika', languageName: 'Afrikaans', language: 'af', country: 'ZA', spelling: true, quiz: false, user: false, school: false, currency: 'zar' })]
] as const

/** List of usable locales */

export const locales = [..._localeMap.map(l => l[1])] as const

/** List of usable Locale codes */
export const Locale = _localeMap.map(l => l[0])
export type Locale = typeof _localeMap[number][0]

export function getLocaleFromUnderscored (underscored: string): LocaleData {
  const locale = locales.find(l => l.underscored === underscored)
  return locale || locales.find(l => l.code === 'en-gb')!
}

export function getLocaleFromCode (code: string): LocaleData {
  const locale = locales.find(l => l.code === code)
  return locale || locales.find(l => l.code === 'en-gb')!
}

export function getLocaleFromCountryCode (countryCode: string): LocaleData {
  countryCode = countryCode.toUpperCase()

  const locale = locales.find(l => l.country === countryCode)

  if (!locale) {
    // in the future we might try to match on language - but not now - just default to GB.
    // const localeCountry = locale.iso.split('-')[0]
    return locales.find(l => l.code === 'en-gb')!
  }

  return locale
}

export function checkIsLocale (locale: string): boolean {
  return (
    locales.find(l => l.code === locale.toLocaleLowerCase()) !== undefined || // Search locales i.e. "en-gb"
    locales.find(l => l.language === locale.toLowerCase()) !== undefined // Search locale languages i.e. "en"
  )
}
