import { Vue, Component } from 'vue-property-decorator'
// @ts-ignore
import Modal from '../components/Modal.vue'
// @ts-ignore
import PromptModal from '../components/PromptModal.vue'

export interface ModalButton<TKey extends string> {
  key: TKey
  type?: 'default' | 'primary' | 'success' | 'info' | 'danger'
  label: string
  disabled?: boolean
  autoFocus?: boolean
}

export interface ModalOptions<TKey extends string> {
  title: string
  message: string
  console?: string
  buttons: readonly ModalButton<TKey>[]
}

export interface PromptModalOptions<TKey extends string> {
  title: string
  message: string
  console?: string
  label?: string
  defaultValue?: string
  buttons: readonly ModalButton<TKey>[]
}

export interface SimpleModelOptions {
  title: string
  message: string
  console?: string
  customLabel?: string
}

export interface SimplePromptModelOptions {
  title: string
  message: string
  console?: string
  label?: string
  defaultValue?: string
}

@Component
export default class ModalMixin extends Vue {
  public openModal<TKey extends string> (
    options: ModalOptions<TKey>
  ): Promise<TKey | 'cancel'> {
    return new Promise((resolve) => {
      const modal = this.$buefy.modal.open({
        component: Modal,
        props: options,
        hasModalCard: true,
        events: {
          'on-button-click': (key: TKey) => {
            modal.close()
            resolve(key)
          }
        },
        onCancel: () => resolve('cancel')
      })
    })
  }

  public openPrompt<TKey extends string> (
    options: PromptModalOptions<TKey>
  ): Promise<{ key: TKey | 'cancel'; value: string | null }> {
    return new Promise((resolve) => {
      const modal = this.$buefy.modal.open({
        component: PromptModal,
        props: options,
        hasModalCard: true,
        events: {
          'on-button-click': (key: TKey, value: string | null) => {
            modal.close()
            resolve({ key, value })
          }
        },
        onCancel: () => resolve({ key: 'cancel', value: null })
      })
    })
  }

  public async alert (options: SimpleModelOptions) {
    await this.openModal({
      ...options,
      buttons: [{ key: 'ok', type: 'default', label: options.customLabel || 'OK', autoFocus: true }]
    })
  }

  public async confirm (options: SimpleModelOptions) {
    const result = await this.openModal({
      ...options,
      buttons: [
        { key: 'cancel', type: 'default', label: 'Cancel' },
        { key: 'ok', type: 'primary', label: 'OK', autoFocus: true }
      ]
    })

    return result === 'ok'
  }

  public async confirmFork (options: ModalOptions<string>) {
    const result = await this.openModal({
      ...options
    })

    return result
  }

  public async deleteConfirm (row: { name: string }, options?: SimpleModelOptions) {
    const title = options && options.title ? options.title : 'Delete Record'
    const message = options && options.message ? options.message : `Are you sure you wish to delete "${row.name}"?`
    const result = await this.openModal({
      title,
      message,
      buttons: [
        { key: 'cancel', type: 'default', label: 'Cancel' },
        { key: 'delete', type: 'danger', label: options?.customLabel ? options.customLabel : 'Delete' }
      ]
    })

    return result === 'delete'
  }

  public async prompt (options: SimplePromptModelOptions) {
    const { key, value } = await this.openPrompt({
      ...options,
      buttons: [
        { key: 'cancel', type: 'default', label: 'Cancel' },
        { key: 'ok', type: 'primary', label: 'OK' }
      ]
    })

    if (key === 'cancel') {
      return null
    }

    return value
  }
}
