import { PhonicsGraphemeId } from '../../../scripts/phonics/graphemes'
import { ArrayElement, DictionaryInfo, ISO8601Date, PhonicsGameIdent } from '..'
import { FileUpload, StoredImage, Locale } from '../common'

export * from './phonicsGames'

export const PhonicsComprehensionScore = [1, 2, 3] as const
export type PhonicsComprehensionScore = ArrayElement<typeof PhonicsComprehensionScore>

export interface AddAssessmentRequest {
  pupil_id: number
  element_id: PhonicsGraphemeId | number
  element_type: PhonicsAssessmentType
  score: PhonicsComprehensionScore
  comment: string | null
}

export interface AddPhonicsAssessmentData {
  pupil_id: number
  teacher_id: number
  results: readonly {
    element_id: PhonicsGraphemeId | number
    element_type: PhonicsAssessmentType
    score: PhonicsComprehensionScore
    comment: string | null
  }[]
}

export interface AddScreeningAssessmentData {
  pupil_id: number
  teacher_id: number
  results: readonly {
    word: {
      dictionary_id: number
      create_assessment: boolean // should we add a row to assessment table for the word as well as the grapheme
      score: PhonicsComprehensionScore
      comment: string | null
    }
    graphemes: readonly {
      element_id: PhonicsGraphemeId
      score: PhonicsComprehensionScore
      comment: string | null
    }[]
  }[],
  screening: boolean
}

export interface PhonicsAssessmentInfo {
  id: number
  pupil_id: number
  pupil_username: string
  pupil_display_name: string
  teacher_id: number
  teacher_username: string
  teacher_display_name: string
  element_id: PhonicsGraphemeId | number
  element_type: PhonicsAssessmentType
  score: PhonicsComprehensionScore
  date: ISO8601Date
}

export interface PhonicsWordAssessmentInfo extends PhonicsAssessmentInfo {
  word: string
}

export interface PhonicsComprehensionInfo {
  element_id: PhonicsGraphemeId | number
  element_type: PhonicsAssessmentType
  score: PhonicsComprehensionScore
  created?: ISO8601Date
}

export interface PhonicsUserComprehensionInfo {
  name: string // name added back in
  user_id: number
  username: string
  display_name: string
  comprehension: PhonicsComprehensionInfo[]
}

export interface PhonicsGroupComprehensionInfo {
  group_id: number
  group_name: string
  pupils: PhonicsUserComprehensionInfo[]
}

export interface PhonicsPurchases {
  purchased_packs: number
  assigned_packs: number
}

export interface PhonicsScreeningQuestion {
  word: DictionaryInfo
  section: 0 | 1 | 2 | 3
}

export const PhonicsAssessmentType = ['gpc', 'element', 'word', 'hfw'] as const
export type PhonicsAssessmentType = ArrayElement<typeof PhonicsAssessmentType>

export interface PhonicsHomeworkOptions {
  grapheme: PhonicsGraphemeId | null
  game: PhonicsGameIdent | null
}

export interface DecodableBookStructure {
  id: number
  title: string
  children: DecodableBookStructure[]
  books: DecodableBookInfo[]
}

export interface DecodableBookInfo {
  name: string
  lesson_ident: string,
  image: StoredImage | null
}

export interface PhonemesInfo {
  id: number
  locale: Locale
  code: string
  name: string
  ipa: string | null
}

export interface AddPhonemesRequest {
  locale: Locale
  code: string
  name: string
  ipa: string | null
  file: FileUpload | null
}

export interface EditPhonemesRequest {
  code?: string
  name?: string
  ipa?: string | null
  file?: FileUpload | null
}
