





















































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import ComponentHelperBase from '../mixins/ComponentHelperBase'
import { TableState, PagedResults, IdRecord, getLookup, Api } from '../api'
import { locales } from '../i18n/index'

@Component({ })
export default class LookupInput extends Mixins(ComponentHelperBase) {
  @Prop({ required: true })
  private readonly typeName!: string

  @Prop({ required: true })
  private readonly value!: number

  @Prop({ required: true })
  private readonly emptyLabel!: string

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean

  @Prop({ required: false, default: false })
  private readonly idArray!: boolean

  private id: number | null = null
  private name: string | null = null

  private locales = locales

  private isPicking = false
  private hasSearched = false

  private selectedItems: number[] = []

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<IdRecord> = {
    items: [],
    total: 0
  }

  private get multiSelection () {
    return ['curriculum_resources', 'lessons'].includes(this.typeName)
  }

  public mounted () {
    this.$watch('value', (id) => {
      this.id = id
      this.refreshName()
    })

    this.refreshName()
  }

  public data () {
    return {
      id: this.$props.value
    }
  }

  private async refreshName () {
    const lookup = getLookup(this.typeName)

    if (this.id) {
      const record = await lookup.getOne.call(Api, this.id)

      this.name = (record as any).name || (record as any).title
    } else {
      this.name = this.$props.emptyLabel || '(Empty)'
    }
  }

  private async loadData () {
    // prevent blank search on modal load
    if (this.tableState.term === '' && !this.hasSearched) {
      return
    }

    const lookup = getLookup(this.typeName)

    this.results = await lookup.getPaged.call(Api, this.tableState)
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSortChange (col: string) {
    const { sort, dir } = this.tableState

    if (sort !== col) {
      this.tableState.sort = col
      this.tableState.dir = 'asc'
    } else {
      this.tableState.dir = dir === 'asc' ? 'desc' : 'asc'
    }

    this.loadData()
  }

  private onSearchChange () {
    this.hasSearched = true
    this.loadData()
  }

  private onLookup () {
    this.isPicking = true
    this.hasSearched = false

    this.loadData()
  }

  private onSelect (row: IdRecord) {
    const id = this.idArray ? [row.id] : row.id
    this.$emit('input', id, row)

    this.isPicking = false
  }

  private onAddResources () {
    this.$emit('input', this.selectedItems)

    this.selectedItems = []
    this.isPicking = false
  }

  private onHideLookup () {
    this.isPicking = false
  }
}
