

















































import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'
import { Api, ErrorReportInfo } from '../api'
import ModalMixin from '../mixins/ModalMixin'

@Component({ components: {} })
export default class ErrorModal extends mixins(ModalMixin) {
  @Prop({ required: true })
  public err!: Error

  @Prop({ required: true })
  public action!: string | null

  private detailOpen = false
  private reportCode: number | null = null

  public async mounted () {
    await this.$nextTick();

    (this.$refs.closeButton as HTMLButtonElement).focus()
  }

  public closeErrorModal () {
    this.$emit('on-close')
  }

  public async sendErrorReport () {
    const report: ErrorReportInfo = {
      user_id: null,
      url: window.location.href,
      user_agent: navigator.userAgent,
      action: this.action,
      message: this.err.message,
      stack: this.err.stack || ''
    }

    const { id } = await Api.sendErrorReport(report)

    this.reportCode = id!
  }
}
