/* eslint-disable quote-props */
/* eslint-disable camelcase */
import { PhonicsGraphemeId } from '../../../scripts/phonics/graphemes'
import { FluencyGameSettings } from '../../../scripts/factories/fluencymethods'
import { QuestionFluencyParams } from '../../../scripts/factories/number'
import { numericKeys } from '../../../utils'
import { ArrayElement, Bit, FileUpload, GameTheme, IdOrIdent, ImageUpload, ISO8601Date, Locale, MixedContent, Opaque, ResourceAvailability, ResourceRequiredPermissions, StoredFile, StoredImage, UserSummary, UUID } from '../common'
import { LessonEpisodeIdent } from '../lesson'
import { CardMedium, CardType } from '../phonics/session/match'
import { BalloonColor } from '../phonics/session/indy'
import { PhonicsGameIdent } from '..'
import { GenericQuestionAnswer, QuestionData, QuestionDataType } from './question_data'

export * from './question_data'

export interface ExternalCurriculumInfo {
  id: number
  type: 'ExternalCurriculumInfo'
  url_key: string
  name: string
  description: string
  locale: Locale
  edu_region: Locale
  published: boolean
}

export interface ExternalCurriculumNodeInfo {
  id: number
  type: 'ExternalCurriculumNodeInfo'
  parent_id: number | null
  curriculum_id: number
  url_key: string
  url_path: string
  hierarchy: string
  name: string
  description: string
  qualified_name: string
  curriculum_name: string
  image: StoredImage | null
  domain: string
  child_count?: number
}

export interface ExternalCurriculumNodeCompetenceInfo {
  user_id: number
  node_id: number
  child_nodes_count?: number
  name: string
  data: ExternalCurriculumNodeCompetenceData
}

export interface ExternalCurriculumNodeCompetenceData {
  total_answered: number
  answered_correctly: number
  accuracy: number
  max_competence_score: number
  current_competence_score: number
  last_scores: number[]
}

export interface QuizPackInfo {
  id: number
  name: string
  description: string
  price: number | null
  product_key: string | null
  sku: string
  data: QuizPackData
}

export interface QuizPackData {
  /** Hide settings options on lessons & the game and only allow defaults */
  hide_settings?: boolean
}

export interface QuizCurriculumInfo {
  id: number
  pack_id: number
  url_key: string
  name: string
  description: string
  locale: Locale
  data: QuizCurriculumData
  pack_name: string
  product: CurriculumProduct
}

export interface QuizCurriculumData {
  domains?: string[]
  roots?: string[]
}

export interface QuizCurriculumDataToEdit {
  domains?: readonly string[]
  roots?: readonly string[]
}

export interface QuizCurriculumNodeInfo {
  id: number
  parent_id: number | null
  curriculum_id: number
  url_key: string
  url_path: string
  hierarchy: string
  name: string
  description: string
  qualified_name: string
  resource_ids: readonly number[]
  resources: readonly ResourceSummary[]
  lesson_ids: readonly number[]
  lessons: readonly LessonSummary[]
  // pack_ids: readonly number[]
  // packs: readonly PackSummary[]
  set_ids: readonly number[]
  sets: readonly QuestionSetSummary[]
  curriculum_name: string
  image: StoredImage | null
  sort_order: number | null
  hidden: Bit
}

export interface ResourceLinkedNode {
  id: number
  parent_id: number | null
  curriculum_id: number
  url_key: string
  url_path: string
  hierarchy: string
  name: string
  description: string
  qualified_name: string
}

export interface ResourceSummary {
  id: number
  ident: string | null
  url_key: string
  title: string
}

export interface LessonSummary {
  id: number
  title: string
}

export interface PackSummary {
  id: number
  name: string
}

/** Summary of `ExternalCurriculumNodeInfo` */
export interface ExtNodeSummary {
  id: number
  name: string
  qualified_name: string
}

export interface QuestionSetSummary {
  id: number
  name: string
  nodes: readonly ExtNodeSummary[]
}

export interface QuizCurriculumNodeAndChildren {
  curriculum: QuizCurriculumInfo
  node: QuizCurriculumNodeInfo | null
  children: readonly QuizCurriculumNodeInfo[]
}

export interface QuestionSetParams {
  /**
   * single = Only allow a single game play
   * free_play= Allow multiple game plays
   **/
  play_type: 'single' | 'free_play'

  /** `ordered` = select at random but then present them in ascending difficulty */
  jumble_questions: boolean | 'ordered'

  jumble_answers: boolean

  /** (`free_play` only) Whether or not to allow replay at the end of the game */
  allow_repeat?: boolean
  /** (`free_play` only) Minimum number of games needed to be played */
  min_game_num?: number
  /** (`free_play` only) Minimum required percentage to pass */
  min_percentage?: number
}

export const QuestionSetParamsDefault: QuestionSetParams = {
  play_type: 'free_play',
  jumble_answers: true,
  jumble_questions: 'ordered',
  min_game_num: 1,
  min_percentage: 70
}

export type QuestionSetPlayType = QuestionSetParams['play_type']

export interface QuizQuestionSetInfo {
  id: number
  ident: QuestionSetIdentPlusAlts
  owner_id: number
  parent_id: number | null
  node_ids: readonly number[]
  question_count: number
  name: string
  theme: GameTheme
  description: string
  internal_key: string | null
  image: StoredImage | null
  availability: ResourceAvailability
  required_permissions: readonly ResourceRequiredPermissions[]
  locale: Locale
  hidden: QuizQuestionSetHiddenStatusKey
  data: QuestionSetData
  created: ISO8601Date
  updated: ISO8601Date
  /** Resolved node array that mirrors `node_ids` */
  nodes: readonly ExtNodeSummary[]
  is_favourite: boolean
  rating: number
  owner: UserSummary
  fluencyGame?: FluencyGameSettings
  subjects: QuestionSetSubjectData[]
  ages: QuizAge[]
  curricula: QuizCurriculumInfo[]
}

export interface FeaturedQuizQuestionSet {
  id: number
  question_set_id: number
  question_set?: Pick<QuizQuestionSetInfo, 'id' | 'ident' | 'name' | 'image'>
  locale: Locale
  sort_order: number
}

export const QuizQuestionSetHiddenStatus = {
  0: 'Public',
  1: 'Unpublished',
  2: 'Private'
} as const

export const QuizQuestionSetHiddenStatusKeys = numericKeys(QuizQuestionSetHiddenStatus)

export type QuizQuestionSetHiddenStatusKey = ArrayElement<typeof QuizQuestionSetHiddenStatusKeys>

export interface QuizSubjectInfo {
  id: number
  text: string
  quiz_count: number
  lesson_count: number
}

export const QuizAge = ['0-5', '5-7', '7-11', '11-13', '13-16', '16-18', '18+'] as const
export type QuizAge = ArrayElement<typeof QuizAge>

export interface QuestionSetUserFilters {
  locale?: Locale,
  subjects?: number[]
  ages?: QuizAge[]
}

export interface TrendingQuestionSetInfo {
  set: QuizQuestionSetInfo
  ratio: number
}

export interface QuestionSetData {
  params: QuestionSetParams
  levels: readonly QuestionLevel[]
  intro?: QuestionSetIntroData
  is_reading_layout?: boolean
  print_settings?: QuizQuestionPrintSettings
  video?: QuestionSetVideoData
}

export interface QuestionSetIntroData {
  title: string
  image?: StoredImage | null
  new_image?: ImageUpload | null
  video?: StoredFile | null
  new_video?: FileUpload | StoredFile | null
  video_url?: string
  content: MixedContent
  type: 'text' | 'image' | 'video'
}

export interface QuestionSetVideoData {
  enabled: boolean
  type: 'file' | 'url'
  video?: StoredFile | null
  new_video?: FileUpload | StoredFile | null
  url?: string
}

export interface QuestionSetSubjectData {
  id: number
  text: string
}

export const QuestionSetDataDefault: QuestionSetData = {
  params: QuestionSetParamsDefault,
  levels: [],
  is_reading_layout: false,
  print_settings: { rows: 2, columns: 1 }
}

export interface QuestionLevel {
  name: string
}

export interface QuizQuestionInfo {
  id: number
  version_id: number
  set_id: number
  node_ids: readonly number[]
  sort_order: number
  question: MixedContent
  data: QuestionData
  image: StoredImage | null
  new_image?: ImageUpload | null
  created: ISO8601Date
  updated: ISO8601Date
  /** Resolve question set that mirrors `set_id` */
  set: QuestionSetSummary
  /** Resolved node array that mirrors `node_ids` */
  nodes: readonly ExtNodeSummary[]
}

export interface FluencyQuestionData {
  params?: {
    hasImage?: boolean
  }
}

export interface SortOrderList {
  [id: number]: number
}

/** Respresents a Question Set Ident string i.e. QABCDEF */
export type QuestionSetIdent = Opaque<'QuestionSetIdent', string>

export type QuestionSetIdentPlusAlts = QuestionSetIdent | 'fluency' | 'phonics'

export type QuizHiveIdent = Opaque<'QuizHiveIdent', string>

export interface NewQuizSessionOptions {
  /** Ident string of the question set being played */
  question_set_ident: QuestionSetIdent
  /** Ident string of the lesson episode being played */
  lesson_episode_ident?: LessonEpisodeIdent
  /** Maximum number of questions to give (optional) */
  count?: number
  /** Randomise the order of the questions */
  jumble_questions?: boolean | 'ordered'
}

export interface QuizSession {
  question_set_ident: QuestionSetIdentPlusAlts
  lesson_episode_ident?: LessonEpisodeIdent
  ident?: string
  name: string
  description: string
  theme: GameTheme
  params: QuestionSetParams
  questions: QuizSessionQuestion[]
  /** Epoch in milliseconds */
  started?: number
}

/** Flatten version of `QuizQuestionInfo`, less the bits we don't need. Also can be generated by fluency. */
export type QuizSessionQuestion =
  QuestionData &
  {
    id?: number
    version_id?: number
    question: MixedContent
    image?: StoredImage | null
    created?: ISO8601Date
    updated?: ISO8601Date
    params?: QuestionFluencyParams | PhonicsQuestionParameters
    case_sensitive?: boolean // for text quiz answers
  }

export interface QuizSessionState {
  question_set_ident: QuestionSetIdentPlusAlts
  lesson_episode_ident?: LessonEpisodeIdent | null
  /** The results in the order of the questions that were originally played */
  results: readonly QuizSessionStateResult[]
  score?: number // override the caluclated score - for awarding custom bonuses etc
  fluency_data?: FluencyGameSessionParameters
  phonics_data?: PhonicsGameSessionParameters
}

export interface QuizSessionStateResult {
  answer_id?: UUID
  /** Only set for real database questions, not fluency */
  question_id?: number
  /** Only set for real database questions, not fluency */
  version_id?: number
  /**
   * For single/multi, just the index of the answers picked (i.e. [1, 3])
   * For sort, the complete list of answer indices as they were arranged by the player (i.e. [3, 2, 1, 0], correct will always be [0, 1, 2, 3, ...n])
   * For match, the state of indices of the right side compared to the left (correct will always be [0, 1, 2, 3, ...n])
   **/
  answers: readonly number[]
  freetext?: MixedContent
  question: QuizSessionQuestion
  score: number
  correct: 0 | 1
  /** -1 if question was never given. TODO: ambiguity between not shown/not answered  */
  time_taken: number
}

export interface FluencyGameSessionParameters {
  query: string
  difficulty: 1 | 2 | 3
  timeLimit: number
  seed: string
  json?: string
}

export interface PhonicsGameSessionParameters {
  grapheme_id: PhonicsGraphemeId | null
  difficulty: 1 | 2 | 3
  earnings: number
  game_ident: PhonicsGameIdent
}

export interface PhonicsQuestionParameters {
  dictionary_id?: number
}

export interface PhonicsDisplayObject {
  cardMatch?: {
    id: number
    type: CardType
    medium: CardMedium
    image?: string // url
    audio?: string // url
    text?: string
  }
  indy?: {
    idx: number
    color: BalloonColor
  }
}

/**
 * MATCH INFO
 * ==========
 *
 * If we have these pairs stored as a question:
 * (0) [A = Alpha]
 * (1) [B = Beta]
 * (2) [C = Gamma]
 * (3) [D = Delta]
 *
 * If presented both jumbled and incorrect as:
 *
 * (3) D     (1) Beta
 * (0) A     (0) Alpha    <-- Index match (this is correct)
 * (2) C     (3) Delta
 * (1) B     (2) Gamma
 *
 * The state will be [0, 2, 3, 1] = 1 correct answer (index === value)
 * Easiest way to think about this is: left side index, right side value
 *
 * Correctly matched (but still jumbled):
 *
 * (3) D     (3) Delta
 * (0) A     (0) Alpha
 * (2) C     (2) Gamma
 * (1) B     (1) Beta
 *
 * All indices matched. The state will be [0, 1, 2, 3] = 4 correct answers
 */

/** A previously play quiz session for the purposes of reporting */
export interface QuizSessionInfo {
  id: UUID
  question_set_ident: string
  lesson_set_ident: string | null
  set_name: string
  score: number
  questions: readonly QuizSessionQuestionInfo[]
  phonics_params?: PhonicsGameSessionParameters
}

/** QuizSessionInfo but without the questions (list view) */
export interface QuizSessionSummary {
  id: UUID
  question_set_ident: QuestionSetIdentPlusAlts
  lesson_episode_ident: LessonEpisodeIdent | null
  set_name: string
  score: number
  total_correct: number
  total: number
  created: ISO8601Date
  time_taken: number
}

export interface PhonicsQuizSessionSummary {
  difficulty: 1 | 2 | 3
  grapheme_id: PhonicsGraphemeId
  game_ident: PhonicsGameIdent
  earnings: number
}

export interface QuizSessionQuestionInfo {
  answer_id: UUID
  question_id?: number
  version_id?: number
  type: QuestionDataType
  question: MixedContent
  score: number
  all_answers: readonly GenericQuestionAnswer[]
  correct_answers: readonly GenericQuestionAnswer[]
  given_answers: readonly GenericQuestionAnswer[]
  correct: 0 | 1
  time_taken: number
  freetext?: MixedContent
  params?: QuestionFluencyParams | PhonicsQuestionParameters
}

export interface ReportedQuestionInfo {
  id: number
  type: ReportedQuestionType
  description: string
  created: ISO8601Date
  user_id: number
  user: string
  question_id: number | null
  question_set_id: number
  question_set_title: string
  question?: string
  ignored: boolean
  set_ident: string
}

export interface ReportedLessonInfo {
  id: number
  type: ReportedQuestionType
  description: string
  created: ISO8601Date
  user_id: number
  user: string
  lesson_episode_id: number | null
  lesson_episode_ident: string | null
  lesson_episode_title: string | null
  lesson_id: number
  lesson_title: string
  ignored: boolean
  lesson_ident: string
}

export interface ReportedQuestionToAdd {
  question_id: number
  description: string
  type: ReportedQuestionType
}

export interface ReportedQuestionSetToAdd {
  question_set_id: IdOrIdent
  description: string
  type: ReportedQuestionType
}

export interface ReportedLessonEpisodeToAdd {
  lesson_episode_id: number
  description: string
  type: ReportedQuestionType
}

export interface ReportedLessonToAdd {
  lesson_id: IdOrIdent
  description: string
  type: ReportedQuestionType
}

export const ReportedQuestionType = ['Inappropriate', 'Wrong answer', 'Spelling mistake', 'Missing image', 'Other'] as const
export type ReportedQuestionType = ArrayElement<typeof ReportedQuestionType>

export interface QuestionSetRatingForUser {
  user_rating: number | null,
  user_has_rated: boolean,
  average: number
}

export interface QuizSubjectToAdd {
  text: string
}

export interface QuizQuestionPrintSettings {
  rows: number // out of 8 rows per page - 1/8 2/8 3/8! 4/8?
  columns: number // 1 or 2 column width of a page

}

export interface CloneCurriculumNodeParams {
  curriculumId: number
  parentId: number | null
}

export const CurriculumProduct = ['spelling', 'number', 'phonics', 'quiz', 'literacy'] as const
export type CurriculumProduct = ArrayElement<typeof CurriculumProduct>
