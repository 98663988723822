


































// @ts-ignore
import { extname } from 'path'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { filetypeinfo } from 'magic-bytes.js'
import { StoredFile } from '../api'

/** Example usage: <file-input :record="myRecord" field="file" /> */
@Component
export default class FileInput extends Vue {
  @Prop({ required: true })
  private record!: any

  @Prop({ required: true })
  private field!: string

  @Prop({ default: 'base64' }) fileParseType!: 'base64' | 'arrayBuffer'

  public dropFile: File | null = null

  private parsed: boolean = false

  @Watch('hasFile', { immediate: true })
  hasFileChanged (val: boolean) {
    this.$emit('has-file', val)
  }

  get hasFile () {
    if (this.file !== null && this.file !== undefined) {
      return true
    } else {
      return this.dropFile !== null
    }
  }

  get file (): StoredFile | null | undefined {
    return this.record[this.field]
  }

  public mounted () {
    this.$watch('dropFile', (file: File) => {
      this.parsed = false

      if (this.fileParseType === 'arrayBuffer') {
        this.processFileAsArrayBuffer(file)
      } else {
        this.processFileAsBase64(file)
      }
    })
  }

  processFileAsArrayBuffer (file: File) {
    const reader = new FileReader()
    this.$emit('reading')
    reader.addEventListener('progress', (event: ProgressEvent) => {
      if (event.lengthComputable) {
        this.$emit('progress', 100 * event.loaded / event.total)
      }
    })

    reader.onload = () => {
      const array = reader.result as ArrayBuffer

      const byteArray = new Uint8Array(array)

      const possibleFileTypes = filetypeinfo(byteArray)

      if (possibleFileTypes.length === 0) {
        throw new Error('Unrecognised file type')
      }

      const expressedExtension = extname(file.name)

      const bestFileType = possibleFileTypes.find(t => t.extension === expressedExtension) ?? possibleFileTypes[0]

      this.$emit('progress', 100)
      this.$emit('parsed-data', { buffer: array, extname: bestFileType.extension ?? expressedExtension, mime: bestFileType.mime })
      this.parsed = true
    }

    reader.readAsArrayBuffer(file)
  }

  processFileAsBase64 (file: File) {
    const reader = new FileReader()
    this.$emit('status', 'reading')

    reader.readAsDataURL(file)

    reader.addEventListener('load', () => {
      this.setNewField({
        size: file.size,
        data: reader.result as string,
        extname: extname(file.name)
      })

      this.parsed = true
    })
  }

  private setNewField (file: any) {
    const newFieldName = `new_${this.field}`
    this.record[newFieldName] = file
    this.$emit('input', file)
  }

  private onClear () {
    this.record[this.field] = null
    this.setNewField(null)
  }
}
