/* eslint-disable quote-props */
/* eslint-disable camelcase */
import { numericKeys } from '../../utils'
import { ArrayElement, Bit, FileUpload, GameTheme, ImageUpload, ISO8601Date, Locale, MixedContent, Opaque, ResourceAvailability, ResourceRequiredPermissions, StoredFile, StoredImage, UserSummary } from './common'
import { QuestionLevel, QuestionSetIdent, QuestionSetParams, QuizAge, QuizQuestionInfo, QuizQuestionSetInfo, QuizSessionSummary } from './quiz'
import { CurriculumResourceInfo } from './resources'

export type LessonEpisodeIdent = Opaque<'LessonEpisodeIdent', string>

export interface LessonInfo {
  id: number
  ident: string
  owner_id: number
  title: string
  description: string
  theme: GameTheme
  image: StoredImage | null
  availability: ResourceAvailability
  locale: Locale
  required_permissions: readonly ResourceRequiredPermissions[] | null
  rating: number
  data: LessonData
  created: ISO8601Date
  updated: ISO8601Date
  is_favourite: boolean
  owner: UserSummary
  hidden: LessonHiddenStatusKey
  subjects: LessonSubjectData[]
  ages: QuizAge[]
}

export const LessonHiddenStatus = {
  0: 'Public',
  1: 'Unpublished',
  2: 'Private'
} as const

export const LessonHiddenStatusKeys = numericKeys(LessonHiddenStatus)

export type LessonHiddenStatusKey = ArrayElement<typeof LessonHiddenStatusKeys>

export interface LessonData {
  levels?: readonly QuestionLevel[]
}

export interface LessonEpisodeInfo {
  id: number
  ident: LessonEpisodeIdent
  lesson_id: number
  title: string
  level: number
  sort_order: number
  activity: LessonEpisodeActivity | null
  created: ISO8601Date
  updated: ISO8601Date
}

export interface LessonEpisodeResponseInfo {
  id?: number
  user_id: number
  lesson_episode_ident: LessonEpisodeIdent
  data: LessonEpisodeResponseData | null
  created: ISO8601Date
  updated: ISO8601Date
  user?: UserSummary
}

export interface LessonEpisodeResponseData {
  text?: string
  image?: StoredImage | null
  new_image?: ImageUpload | null
  video?: StoredFile | null
  new_video?: FileUpload | null
}

// ==== Stored in the database

export type LessonEpisodeActivity = MathsGameActivity | ResourceActivity | QuestionSetActivity | MediaActivity | SpellingGameActivity

export type LessonEpisodeActivityType = LessonEpisodeActivity['type']

export interface MathsGameActivity {
  type: 'maths'
  ident: string
  params: MathsGameParams
}

export interface MathsGameParams {
  min_game_num: number
  time_limit: number
}

export interface SpellingGameActivity {
  type: 'spelling'
  ident: string
  params: SpellingGameParams
}

export interface SpellingGameParams {
  min_game_num: number
  time_limit: number
}

export interface ResourceActivity {
  type: 'resource'
  resource_id: number
}

export interface MediaActivity {
  type: 'media'
  params: MediaParams
}

export interface MediaParams {
  title: string
  image?: StoredImage | null
  new_image?: ImageUpload | null
  video?: StoredFile | null
  new_video?: FileUpload | null
  video_url?: string
  book?: StoredFile | null
  new_book?: FileUpload | null
  content: MixedContent
  type: 'text' | 'image' | 'video' | 'book'
  response_allow: boolean
  response_required: boolean
  response_text: boolean
  response_upload: boolean
  theme: GameTheme
  phonics?: PhonicsMediaParams
}

export interface PhonicsMediaParams {
  curlyk?: boolean
}

export interface MediaResponse {
  text: string,
  image?: StoredImage | null
  new_image?: ImageUpload | null
}

export interface QuestionSetActivity {
  type: 'question_set'
  ident: QuestionSetIdent
  levels?: readonly QuestionLevel[]
  params?: QuestionSetParams
}

// ==== Resolved data types

export type LessonEpisodeActivityResolved = MathsGameActivityResolved | ResourceActivityResolved | QuestionSetActivityResolved | MediaActivityResolved

export type MediaActivityResolved = MediaActivity

export type MathsGameActivityResolved = MathsGameActivity

export type ResourceActivityResolved = ResourceActivity & {
  resource: CurriculumResourceInfo
}

export type QuestionSetActivityResolved = QuestionSetActivity & {
  question_set: QuizQuestionSetInfo
  questions: readonly QuizQuestionInfo[]
}

// ==== Top level resolved lesson for game consumption

export interface ResolvedLesson {
  lesson: LessonInfo
  episodes: ResolveLessonEpisode[]
}

export type ResolveLessonEpisode = LessonEpisodeInfo & {
  activityData?: LessonEpisodeActivityResolved | null
}

// ==== Progress

export interface LessonProgress {
  lesson: LessonInfo
  episode_progress_list: readonly LessonEpisodeProgress[]
  times_completed: number
}

export interface LessonEpisodeProgress {
  episode: LessonEpisodeInfo
  progress: LessonEpisodeProgressInfo
}

export interface LessonEpisodeProgressInfo {
  play_count: number
  highest_score?: number
  average_score?: number
  highest_percentage_correct?: number
  average_percentage_correct?: number
  responses?: readonly LessonEpisodeResponseData[]
  sessions?: readonly QuizSessionSummary[]
  outstanding?: string[]
  complete?: Bit
}

export interface LessonUserFilters {
  locale?: Locale,
  subjects?: number[]
  ages?: QuizAge[]
}

export interface LessonSubjectData {
  id: number
  text: string
}
