























import { Vue, Component, Prop } from 'vue-property-decorator'
import draggable from 'vuedraggable'

@Component({ components: { draggable } })
export default class MultiLookupInput extends Vue {
  @Prop({ required: true })
  private readonly typeName!: string

  @Prop({ required: true })
  private readonly value!: number[]

  @Prop({ required: true })
  private readonly emptyLabel!: string

  private drag = false

  private onChange (previousId: number, index: number, ids: number[]) {
    const keepExisting = ids.some(id => id === previousId)
    const newIdsToAdd: number[] = ids.filter(id => !this.value.includes(id))

    if (newIdsToAdd.length === 0 && !keepExisting) {
      alert('No new IDs to add')
      return
    }

    let newValue: number[] = this.value

    if (!keepExisting) {
      newValue[index] = newIdsToAdd.shift()!
    }

    newValue = newValue.concat(newIdsToAdd)

    this.$emit('input', newValue)
  }

  private onAdd () {
    this.$emit('input', [...this.value, null])
  }

  private deleteLookupInput (_id: number) {
    this.$emit('input', this.value.filter(id => id !== _id))
  }

  private onSortChange (value: number[]) {
    this.$emit('input', value)
  }
}
