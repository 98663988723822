import gb from './en-gb'
import { CommonLang } from './types'

const lang: CommonLang = {
  ...gb,
  name: 'ZA',
  County: 'County / Region',
  SchoolDistricts: 'School Districts / trusts.',
  currency: 'R',
  spellingPersonalPrice: '1250',
  spellingTeacherPrice: '3060',
  spellingPremiumPrice: '85',
  spellingAppPrice: '70',
  pupilsPerSpellingClassroom: '36',
  natCurriculum: 'South African Primary Curriculum',
  natCurr2: 'Curriculum',
  customText: 'Click a title and scan the QR code to save the list to your lists in the Spelling Shed app.',
  spellingCurriculumKey: 'spelling-shed-scheme-za',
  spellingCurriculumId: '6',
  mathsPersonalPrice: '1250',
  mathsTeacherPrice: '3060',
  mathsPremiumPrice: '85',
  mathsAppPrice: '75',
  pupilsPerMathsClassroom: '36',
  school_year: 'grade',
  'phonics-decodable-1-cost': '2800',
  'phonics-decodable-2-cost': '2000',
  'phonics-pack-1-cost': '13000',
  'phonics-pack-2-cost': '13000',
  'phonics-digital-cost': '3000',
  'phonics-vat': '',
  'phonics-currency': 'zar',
  chapter: 'stage',
  Chapter: 'Stage',
  set: 'term',
  Set: 'Term',
  subset: 'week',
  Subset: 'Week'
}

export default lang
