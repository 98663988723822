export const HiveStatus = ['created', 'started', 'finished'] as const
export type HiveStatus = typeof HiveStatus[number]

export const HiveRole = ['player', 'observer', 'manager'] as const
export type HiveRole = typeof HiveRole[number]

export interface HiveWordData {
  word: string
  spelling_word_id: number
  dictionary_id: number
  parent_dictionary_id: number | null
}

export interface GetHiveGamesFilters {
  owner_id?: number
  school_id?: number
  status?: HiveStatus[]
  room?: number
  id?: number
  creator_type?: {
    teacher?: boolean
    pupil?: boolean
  }
}

export interface RoundDataItem {
  id?: number
  timestamp?: string
  user_id: number
  round: number
  word: string
  correct: 0 | 1,
  answer_given: string
  score: number
  name: string
  username: string
  display_name: string
  hive_id: number
}

export interface PlayerRoundsResponse {
  room: number
  rounds: PlayerRound[]
}

export interface PlayerRound {
  round: number
  data: RoundDataItem | null
}
