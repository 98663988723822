

























import { Vue, Component, Prop } from 'vue-property-decorator'
import { FileAttachment } from '../api'

@Component({ })
export default class MultiFileAttachmentField extends Vue {
  @Prop({ required: true })
  private readonly value!: FileAttachment[]

  private onAdd () {
    this.$emit('input', [...this.value, { name: '', description: '' }])
  }
}
