import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import { State } from './types'

Vue.use(Vuex)

const getters = {
  hasSpelling: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.spelling === 1).length > 0
  },
  hasNumber: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.number === 1).length > 0
  },
  isSpellingTrial: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.type !== 'free' && sub.spelling === 1).length === 0
  },
  isNumberTrial: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.type !== 'free' && sub.number === 1).length === 0
  },
  isPremium: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.premium === 1 && sub.spelling === 1).length > 0
  },
  hasFullSubscription: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.type !== 'free').length > 0
  },
  isExpiring: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }

    if (state.user.school.subscriptions.filter(sub => sub.type !== 'free' && !sub.subscription_id).length) {
      return true
    }
    return false
  },
  hasSubscription: (state: State) => {
    if (state.user == null || state.user.school == null) {
      console.log('no user')
      return false
    }
    if (state.user.school.subscriptions == null) {
      console.log('no subs')
      return false
    }
    const subs = state.user.school.subscriptions
    for (let i = 0; i < subs.length; i++) {
      const expDate = new Date(subs[i].expiry)
      console.log(expDate)
      console.log(subs[i])
      const now = new Date()
      if (now < expDate) {
        return true
      }
    }
    return false
  },
  hasMultiSchool: (state: State) => {
    if (!state.user) {
      return false
    }
    return (state.user.all_schools && (state.user.all_schools === '*' || state.user.all_schools.split(',').length > 1))
  },
  hasEdShed: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.group === 'edshed').length > 0
  },
  hasLitShed: (state: State) => {
    if (!state.user) {
      return false
    }
    if (!state.user.school) {
      return false
    }
    if (!state.user.school.subscriptions || state.user.school.subscriptions.length === 0) {
      return false
    }
    return state.user.school.subscriptions.filter(sub => sub.group === 'litshed').length > 0
  }
}

export type Getters = typeof getters

export default new Vuex.Store<State>({
  state,
  actions,
  mutations,
  getters
})
