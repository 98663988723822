/* eslint-disable camelcase */
import { ArrayElement, Bit, ISO8601Date, JsonString, Locale, Scores } from './common'
import { PupilHomework } from './homework'
import { SubscriptionPlanInfo, SubscriptionProductType, SubscriptionType } from './subscriptions'
import { GroupInfo } from './groups'
import { Avatar } from './avatar'
import { CountryInfo } from './country'
import { SchoolOrgType } from './school'
import { CountryCode, OneRosterProvider } from '.'

export type SchoolRole = 'student' | 'school_admin' | 'teacher' | 'district_admin'

export const FontOption = ['dyslexic', 'sassoon', 'sassoonCurly', 'sassoonUs', 'sassoonZa', 'muli'] as const
export type FontOption = ArrayElement<typeof FontOption>

export const UserPermissionKeys = [...SubscriptionProductType, 'phonics_lite_1', 'phonics_lite_2', 'spelling-resources', 'number-resources', 'litshed-resources', 'phonics-resources'] as const
export type UserPermissionKeys = typeof UserPermissionKeys[number]

export const SchoolPermissionKeys = ['flashcard-tool', 'phonics-tracker'] as const
export type SchoolPermissionKeys = typeof SchoolPermissionKeys[number]

export interface UserSettings {
  disableTimer?: boolean
  disableLeagues?: boolean
  musicOn?: boolean
  soundFXOn?: boolean
  letterSoundsOn?: boolean
  invertCalcOn?: boolean
  letterNamesOn?: boolean
  fontPreference?: FontOption
}

export interface UserCreateOptions {
  username: string
  password: string
  passwordType?: 'hash' | 'encrypt' | 'anon'
  name: string
  email?: string
  displayName?: string
  upn?: string
  locale: Locale
  highScore?: number
  remember?: number
  marketing?: Bit
  role?: UserRoles
  dob?: Date
  settings?: UserSettings
  stage_number?: number | null
  stage_spelling?: number | null
  school_id?: number
}

export interface UserStub {
  id: number
  username: string
  name: string
  display_name: string
  email: string | null
  email_verified: ISO8601Date | null
  locale: Locale
  avatar: JsonString<Avatar>
  school?: {
    id: number
    school_name: string
    role: UserRoles
  },
  permissions: UserPermissionKeys[]
}

export interface UserModelBase {
  id: number
  secret: string | null
  username: string
  password?: string
  password_type?: 'hash' | 'encrypt' | 'anon'
  name: string
  display_name: string
  email: string | null
  marketing: 0 | 1

  locale: Locale
  /** See `Avatar` */
  avatar: JsonString<Avatar>
  wonde_upi: string | null
  clever_oid: string | null
  one_roster_id: string | null
  one_roster_type: OneRosterProvider | null
  one_roster_tenant_id: number | null
  superuser?: 0 | 1
  superduperuser?: 0 | 1
  dictionary_editor?: 0 | 1
  tester?: 0 | 1
  email_verified?: ISO8601Date | null
  activity?: UserActivityModel

  school?: SchoolModel
  subscription_ids?: string
  all_schools?: string | null
  wonde_session?: boolean
  clever_session?: boolean
  one_roster_session?: boolean
  backgroundRefresh?: boolean
  sessionTime?: number
  token?: string
  district_id: number | null
  district_role?: string | null
  // all_plans: SubscriptionPlanInfo[]

  groups?: string
  upn?: string | null

  group_ids?: string

  dob?: ISO8601Date | null

  stage_spelling?: number | null
  stage_number?: number | null

  settings: UserSettings

  imitatingUser?: UserModel | 'me'
  school_username?: string | null
  stale: boolean
}

export interface UpdateAccountRequest {
  username?: string
  school_username?: string
  displayname?: string
  password?: string
  name?: string
  email?: string
  locale?: Locale
  avatar?: JsonString<Avatar>
  marketing?: 0 | 1
  tester?: 0 | 1
  dob?: Date
  settings?: JsonString<UserSettings>
}

export type UserModel = UserModelBase & UserAccessLevels & UserSubscriptions & Scores

export interface UserAccessLevels {
  spelling_access_level?: UserSubscriptionLevel
  number_access_level?: UserSubscriptionLevel
  permissions: UserPermissionKeys[]
}

export interface UserSubscriptions {
  has_spelling?: boolean
  has_number?: boolean
  has_phonics?: boolean
  has_phonics_lite_1?: boolean
  has_phonics_lite_2?: boolean
  has_literacy?: boolean
}

export const UserSubscriptionLevel = ['full', 'trial', 'freemium'] as const
export type UserSubscriptionLevel = ArrayElement<typeof UserSubscriptionLevel>

export interface DeletedUserInfo {
  user_id: number
  username: string
  name: string
  display_name: string
  email: string | null
  upn: string | null
  wonde_upi: string | null
  clever_oid: string | null
  one_roster_type: OneRosterProvider | null
  one_roster_id: string | null
  one_roster_tenant_id: number | null
  avatar: JsonString<Avatar>
  linked_objects: any
}

export interface UpdateDeletedUserOptions {
  username?: string
  school_username?: string
  email?: null
  one_roster_id?: null
  upn?: null
  clever_oid?: null
  wonde_upi?: null
}

export const DeletedUserFields = ['username', 'school_username', 'email', 'one_roster_id', 'upn', 'clever_oid', 'wonde_upi'] as const
export type DeletedUserFields = typeof DeletedUserFields[number]
export interface MergeDeletedUserOptions {
  existingUser: number
  deletedUser: number
  keptUser: 0 | 1
  field: DeletedUserFields
}

export interface UserActivityModel {
  spelling: number[]
  number: number[]
  quiz: {
    spelling: number[]
    number: number[]
    quiz: number[]
    phonics: number[]
  }
  phonics: number[]
}

// Used for API calls where the payload contains any number of optional properties but requires an id
export type AmbiguousUserModel = { id: number } & Partial<UserModel>

export interface PupilListCustomSearchOptions {
  officialEdShedLists: boolean
  schoolAllLists: boolean
  schoolTeachersLists: boolean
  globalTeachersLists: boolean
}

export interface SchoolGameSettings {
  disableTimer?: boolean
  disableLeagues?: boolean
  pupilListSearchSetting?: 'all' | 'default' | 'custom' | 'disabled'
  pupilListCustomSearchOptions?: PupilListCustomSearchOptions
  fontPreference?: FontOption | null
  pupilsListsSchoolOnly?: boolean
  trap_bath?: boolean
}

export interface SchoolModel {
  id: number
  contact_name: string
  contact_email: string
  contact_phone: string
  school_name: string
  school_address1: string
  school_address2: string
  school_town: string
  school_county: string
  school_postcode: string
  billing_name: string
  billing_address1: string
  billing_address2: string
  billing_town: string
  billing_county: string
  billing_postcode: string
  school_country: string
  school_country_code: CountryCode
  school_country_info: CountryInfo
  district_id: number | null
  school_type: string
  stripe_customer: string | null
  created: Date
  code: string | null
  locale: Locale
  vat_number: string | null
  league_length: number
  wonde_id: string | null
  wonde_status: 'requested' | 'approved' | 'cancelled' | null
  clever_oid: string | null
  clever_status: 'classroom' | 'requested' | 'approved' | 'cancelled' | null
  clever_token: string | null
  one_roster_id: string | null
  one_roster_type: OneRosterProvider | null
  one_roster_tenant_id: number | null

  admin: Bit
  teacher: Bit
  role: UserRoles | null

  subscriptions?: SchoolSubscription[]
  homeworks?: PupilHomework[]
  pupil_count?: number
  teacher_count?: number
  group_count?: number
  account_region: 'GB' | 'US'
  org_type: SchoolOrgType
  xero_contact: string | null

  settings: SchoolGameSettings
  tax_exempt: boolean
  stale: boolean
  permissions?: SchoolPermissionKeys[]
}

export type SimpleSchool = Pick<SchoolModel, 'id' | 'school_name' | 'school_postcode'>

/** A subscription in the context of a user model's school object. Requires user-sensitve product parameters for legacy support */
export interface SchoolSubscription {
  id: number
  creation_timestamp: ISO8601Date
  cancellation_date: ISO8601Date | null
  type: SubscriptionType
  active: Bit
  premium: Bit
  group: string
  spelling: Bit
  expiry: ISO8601Date
  subscription_id: string | null
  number: Bit
  phonics: Bit
  literacy: Bit
  pupil_seats: number | null
  teacher_seats: number | null
  classes: number | null
  invoice_url: string | null
  parent_id: number | null
  school_id: number
  is_metered: boolean
  base_plan: SubscriptionPlanInfo
  add_on_plans: SubscriptionPlanInfo[]
  is_assigned: boolean
}
export interface UserRelations {
  object: number
  subject: number
  isMe: boolean
  isTeacher: boolean // user 2 is user 1's teacher, user 1 is pupil
  isAdmin: boolean // user 2 is admin, user 1 is teacher or pupil
  isAdminOfTeacher: boolean // user 2 is admin, user 1 is teacher
  isPupil: boolean // user 1 is user 2's teacher, or is an admin at their school
  isPupilOfAdmin: boolean // user 1 is a pupil, and user 2 is an admin
  isEmployee: boolean // user 2 is a teacher, user 1 is admin
  sameClass: boolean
  sameSchool: boolean
}

export interface Pupil extends Scores {
  id: number
  email: string
  reg: string
  name: string
  displayname: string
  username: string
  password?: string
  locale: string
  upn: string | null
  wonde_upi: string | null
  clever_oid: string | null
  one_roster_id: string | null
  one_roster_type: OneRosterProvider | null
  district_id: number | null
  dob: ISO8601Date | null
  groupsArray: GroupInfo[]
  /**
   * Comma separated
   */
  groups: string
  groupNames: string[]
  /**
   * Comma separated
   */
  group_ids: string
  /**
   * Comma separated
   */
  subscription_ids: string
  has_spelling: boolean
  has_number: boolean
  has_literacy: boolean
  has_phonics: boolean
  has_phonics_lite_1: boolean
  has_phonics_lite_2: boolean
  permissions: UserPermissionKeys[]
  activity?: UserActivityModel
  password_type: 'hash' | 'encrypt' | 'anon'
  display_name: string
  high_score: number
  high_score_number: number
  high_score_quiz: number
  high_score_phonics: number
  avatar: JsonString<Avatar>

  stage_spelling?: number | null
  stage_number?: number | null

  settings: UserSettings
  school_username?: string | null

  // score: number
  // total_score: string
  // score_number: number
  // total_score_number: string
  // score_quiz: number
  // total_score_quiz: string
  // total_earnings: number
}

export interface Teacher extends Scores {
  id: number
  email: string
  name: string
  displayname: string
  username: string
  locale: string
  wonde_upi: string | null
  clever_oid: string | null
  one_roster_id: string | null
  one_roster_type: OneRosterProvider | null
  district_id: number | null
  /**
   * Comma separated
   */
  groups: string
  /**
   * Comma separated
   */
  group_ids: string
  /**
   * Comma separated
   */
  subscription_ids: string
  has_spelling: boolean
  has_number: boolean
  has_literacy: boolean
  has_phonics: boolean
  has_phonics_lite_1: boolean
  has_phonics_lite_2: boolean
  permissions: UserPermissionKeys[]
  display_name: string
  high_score: number
  high_score_number: number
  high_score_quiz: number
  high_score_phonics: number
  avatar: JsonString<Avatar>
  teacher: Bit
  admin: Bit
  role: SchoolRole
  upn: string | null
  school_username?: string | null
}

export interface AddTeacherRequest {
  name: string
  username: string
  email: string
  password: string
  role: 'teacher'
}
export interface TeacherUpdateRequest {
  email?: string
  password?: string
}

export const UserRoles = ['student', 'teacher', 'school_admin', 'district_admin'] as const
export type UserRoles = typeof UserRoles[number]

export function isValidVatNumber (vat: string | null): boolean {
  if (vat === null) {
    return false
  }

  const regex = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/gm

  let m
  let valid = false

  while ((m = regex.exec(vat)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((_match, _groupIndex) => {
      valid = true
    })
  }

  return valid
}

interface CheckUserQueryEmail {
  email: string
}

interface CheckUserQueryUsername {
  username: string
}

interface CheckUserQuerySchoolUsername {
  school_username: string
}

export type CheckUserQuery = CheckUserQueryEmail | CheckUserQuerySchoolUsername | CheckUserQueryUsername
