


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { StoredImage } from '../api'

@Component({ })
export default class ImageModal extends Vue {
  @Prop({ required: true })
  private title!: string

  @Prop({ required: true })
  private image!: StoredImage

  public mounted () {

  }
}
