import { ServerEnv } from '@/edshed-common/servers'
import { State } from './types'

const state: State = {
  callingAPI: false,
  searching: '',
  user: null,
  token: null,
  api_environment: (process.env.SERVER_ENV ?? 'production') as ServerEnv,
  stripeToken: 'pk_live_0jEWQ79Gdb3ravoFlmIiepXQ',
  stripeTestToken: 'pk_test_G3wU2WGQLPnB2L72i0i2yfvm',
  standardProduct: { name: 'Spelling Shed Standard', description: 'A one year subscription', amount: 6000 },
  premiumProduct: { name: 'Spelling Shed Premium', description: 'A one year subscription', amount: 10000 },
  standardPersonalProduct: { name: 'Spelling Shed Personal', description: 'A one year subscription', amount: 2000 },
  premiumPersonalProduct: { name: 'Spelling Shed Personal', description: 'A one year subscription', amount: 2000 }
}

export default state
