






























// @ts-ignore
import { extname } from 'path'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { StoredImage } from '../api'
import ImageModal from './ImageModal.vue'

/** Example usage: <ImageInput :record="myRecord" field="image" /> */
@Component({ })
export default class ImageInput extends Vue {
  @Prop({ required: true })
  private readonly record!: any

  @Prop({ required: true })
  private readonly field!: string

  @Prop({ required: false })
  private readonly label: string | undefined

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean

  private dropFile: File | null = null
  private imagePreview: string = ''

  get image (): StoredImage | null {
    return this.record[this.field]
  }

  public mounted () {
    this.$watch('dropFile', (file: File) => {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.addEventListener('load', () => {
        this.setNewField({
          data: reader.result as string,
          extname: extname(file.name)
        })
        if (this.dropFile) {
          if (['image/jpeg', 'image/png', 'image/gif'].includes(this.dropFile.type)) {
            this.imagePreview = URL.createObjectURL(this.dropFile)
          }
        }

        this.$emit('image-ready')
      })
    })
  }

  private setNewField (image: any) {
    this.record[this.field] = null
    const newFieldName = `new_${this.field}`
    this.record[newFieldName] = image
  }

  public onClear () {
    this.record[this.field] = null
    this.setNewField(null)
  }

  private showPreview () {
    this.$buefy.modal.open({
      component: ImageModal,
      props: {
        title: this.label ? `Preview for ${this.label}` : 'Preview',
        image: this.image
      },
      hasModalCard: true,
      events: { },
      onCancel: () => { }
    })
  }
}
