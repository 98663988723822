import gb from './en-gb'
import { CommonLang } from './types'

const lang: CommonLang = {
  ...gb,
  name: 'NZ',
  County: 'County / Region',
  SchoolDistricts: 'School Districts / trusts.',
  SchemeText: 'Scheme',
  currency: 'NZ$',
  spellingPersonalPrice: '74.99',
  spellingTeacherPrice: '224.00',
  spellingPremiumPrice: '8.00',
  spellingAppPrice: '4.49',
  pupilsPerSpellingClassroom: '28',
  natCurriculum: 'New Zealand Curriculum',
  natCurr2: 'New Zealand Curriculum',
  customText: 'Click a title and scan the QR code to save the list to your lists in the Spelling Shed app.',
  spellingCurriculumKey: 'spelling-shed-scheme-nz',
  spellingCurriculumId: '10',
  spagCurriculumKey: 'spag-new-zealand',
  spagCurriculumId: '15',
  mathsCurriculumKey: 'maths-shed-teaching-slides-nz',
  mathsPersonalPrice: '74.99',
  mathsTeacherPrice: '224.00',
  mathsPremiumPrice: '8.00',
  mathsAppPrice: '4.49',
  pupilsPerMathsClassroom: '28'
}

export default lang
