import { ArrayElement, FileUpload, ImageUpload, Locale, StoredFile, StoredImage, Nullable } from './common'
import { UserRoles } from './user'

export interface DictionaryInfo {
  id: number
  user_id: number
  word: string
  word_class: DictionaryDefinitionClass | null
  locale: Locale
  errors: string[]
  definitions: DictionaryDefinition[]
  difficulty_index: number
  morphemes: string[]
  syllables: string[]
  sentences: string[]
  phonics: GraphemePhonemePair[]
  synonyms: RelatedDictionaryWord[]
  antonyms: RelatedDictionaryWord[]
  flags: DictionaryFlag[]
  image: StoredImage | null
  audio: StoredFile | null
  parent_word: DictionaryInfo | null
  approved: boolean
  hidden: boolean
  owner_username?: string
  times_used?: number
  user_versions?: number
  elements: DictionaryWordElementInfo[]
  as_in: string | null,
  ipa_definition: string | null,
  variant_type: DictionaryVariantType | null
  variant_phonics: GraphemePhonemePair[] | null
  variant_audio: StoredFile | null
}

export type RelatedDictionaryWord = Pick<DictionaryInfo, 'id' | 'word' | 'definitions'> & { related_to_id: number }

export type LetterTileToolTrackedWord = Nullable<Pick<DictionaryInfo, 'id'>> & {
  word: string
  locale: Locale
  times_used: number
  has_definitions: boolean
  has_phonics: boolean
}

export interface GraphemePhonemePair {
  grapheme: string
  phoneme: string
}

export interface DictionaryDefinition {
  class: DictionaryDefinitionClass
  definition: string
}

export const DictionaryVisibilityOption = ['hidden', 'public'] as const
export type DictionaryVisibilityOption = ArrayElement<typeof DictionaryVisibilityOption>

export const DictionaryOwnerOption = ['edshed', 'user'] as const
export type DictionaryOwnerOption = ArrayElement<typeof DictionaryOwnerOption>

export const DictionaryApprovalOption = ['approved', 'unapproved'] as const
export type DictionaryApprovalOption = ArrayElement<typeof DictionaryApprovalOption>

export const DictionaryDefinitionClass = ['noun', 'verb', 'adjective', 'adverb', 'preposition', 'pronoun', 'conjunction', 'contraction', 'possessive adjective', 'modal verb', 'transitive verb', 'interjection', 'determiner', 'collective noun', 'exclamation', 'proper noun'] as const
export type DictionaryDefinitionClass = ArrayElement<typeof DictionaryDefinitionClass>

export interface DictionaryFilters {
  locale: Locale
  parent_id?: null | number
  visibility?: DictionaryVisibilityOption[]
  owner?: DictionaryOwnerOption[]
  approval?: DictionaryApprovalOption[]
}

export interface AddDictionaryRequest {
  word: string
  word_class: DictionaryDefinitionClass | null
  locale: Locale
  errors: readonly string[]
  definitions: readonly DictionaryDefinition[]
  difficulty_index: number
  morphemes: readonly string[]
  syllables: readonly string[]
  sentences: readonly string[]
  phonics: readonly GraphemePhonemePair[]
  synonyms: readonly Pick<DictionaryInfo, 'id'>[]
  antonyms: readonly Pick<DictionaryInfo, 'id'>[]
  flags: readonly DictionaryFlag[]
  new_image?: ImageUpload | null
  new_audio?: FileUpload | null
  new_variant_audio?: FileUpload | null
  approved?: boolean
  elements: DictionaryWordElementToAdd[]
  as_in: string | null,
  ipa_definition: string | null,
  variant_type: DictionaryVariantType | null
  variant_phonics: GraphemePhonemePair[] | null
}

export interface AddUserDictionaryRequest {
  word: string
  locale: Locale
  parent_id: number | null
  errors: string[] | null
  definitions: DictionaryDefinition[] | null
  difficulty_index: number
  morphemes: string[] | null
  syllables: string[] | null
  sentences: string[] | null
  phonics: GraphemePhonemePair[] | null
  synonyms: Pick<DictionaryInfo, 'id'>[] | null
  antonyms: Pick<DictionaryInfo, 'id'>[] | null
  new_image?: ImageUpload | null
  new_audio?: FileUpload | null
}

export interface DictionaryDataToAdd {
  word: string
  word_class: DictionaryDefinitionClass | null
  locale: Locale
  user_id: number
  parent_id: number | null
  errors: string[] | null
  definitions: DictionaryDefinition[] | null
  difficulty_index: number
  morphemes: string[] | null
  syllables: string[] | null
  sentences: string[] | null
  phonics: GraphemePhonemePair[] | null
  synonyms: Pick<DictionaryInfo, 'id'>[] | null
  antonyms: Pick<DictionaryInfo, 'id'>[] | null
  flags: DictionaryFlag[]
  new_image?: ImageUpload | null
  new_audio?: FileUpload | null
  new_variant_audio?: FileUpload | null
  hidden: boolean
  approved: boolean
  elements: DictionaryWordElementToAdd[] | null
  as_in: string | null
  ipa_definition: string | null
  variant_type: DictionaryVariantType | null
  variant_phonics: GraphemePhonemePair[] | null
}

export interface DictionaryDataToEdit {
  word?: string
  word_class?: DictionaryDefinitionClass | null
  locale?: Locale
  user_id?: number
  parent_id?: number | null
  errors?: string[] | null
  definitions?: DictionaryDefinition[] | null
  difficulty_index?: number
  morphemes?: string[] | null
  syllables?: string[] | null
  sentences?: string[] | null
  phonics?: GraphemePhonemePair[] | null
  synonyms?: Pick<DictionaryInfo, 'id'>[] | null
  antonyms?: Pick<DictionaryInfo, 'id'>[] | null
  flags?: DictionaryFlag[]
  new_image?: ImageUpload | null
  new_audio?: FileUpload | null
  new_variant_audio?: FileUpload | null
  hidden?: boolean
  approved?: boolean
  elements?: DictionaryWordElementToAdd[] | null
  as_in?: string | null
  ipa_definition?: string | null
  variant_type?: DictionaryVariantType | null
  variant_phonics?: GraphemePhonemePair[] | null
}

export const DictionaryDecodabilityFlag = ['Decodable', 'Partially Decodable', 'Eventually Decodable'] as const
export type DictionaryDecodabilityFlag = ArrayElement<typeof DictionaryDecodabilityFlag>

export const DictionaryFlag = ['HFW', ...DictionaryDecodabilityFlag, 'Junk', 'Pseudo'] as const
export type DictionaryFlag = ArrayElement<typeof DictionaryFlag>

export interface GetListsForWordFilters {
  locale?: Locale
  user_roles?: readonly UserRoles[]
  school_id?: number
}

// update the enum() in the variant_type fiel of the dictionary_variant table when adding a new variant to the DictionaryVariantType
export const DictionaryVariantType = ['Trap-bath'] as const
export type DictionaryVariantType = typeof DictionaryVariantType[number]

export interface DictionaryVariantToAdd {
  dictionary_id: number
  variant_type: DictionaryVariantType
  variant_phonics: readonly GraphemePhonemePair[] | null
  new_variant_audio?: FileUpload | null
}

export interface DictionaryWordElementToAdd {
  id: number
  rule_id: number
  index: number
  string: string
}

export interface DictionaryWordElementInfo {
  id: number
  rule_id: number
  rule_name: string
  index: number
  string: string
}
