import { Api } from './methods'
import { IdRecord, PagedResults, TableQuery } from './types'

export interface LookupApi {
  getOne (id: number): Promise<IdRecord>
  getPaged (query: TableQuery): Promise<PagedResults<IdRecord>>
}

/** TO BE EXPANDED */
export type LookupTableNames = 'external_curricula' | 'external_curriculum_nodes' | 'quiz_packs' | 'quiz_curricula' | 'quiz_curriculum_nodes' | 'quiz_question_sets' | 'curriculum_resources' | 'lessons' | 'merch_locations' | 'merch_products'

const LookupTables: Record<LookupTableNames, LookupApi> = {
  external_curricula: {
    getOne (id) {
      return Api.getExternalCurriculum(id)
    },
    getPaged (query) {
      return Api.getExternalCurricula(query, {})
    }
  },

  external_curriculum_nodes: {
    getOne (id) {
      return Api.getExternalCurriculumNode(id)
    },
    getPaged (query) {
      return Api.getExternalCurriculumNodes({}, query)
    }
  },

  quiz_packs: {
    getOne (id) {
      return Api.getPack(id)
    },
    getPaged (query) {
      return Api.getPacks(query)
    }
  },

  quiz_curricula: {
    getOne (id) {
      return Api.getCurriculum(id)
    },
    getPaged (query) {
      return Api.getCurricula({}, query)
    }
  },

  quiz_curriculum_nodes: {
    getOne (id) {
      return Api.getCurriculumNode(id)
    },
    getPaged (query) {
      return Api.getCurriculumNodes({}, query)
    }
  },

  quiz_question_sets: {
    getOne (id) {
      return Api.getQuestionSet(id)
    },
    getPaged (query) {
      return Api.getQuestionSets(undefined, query, {})
    }
  },

  curriculum_resources: {
    getOne (id) {
      return Api.getResource(id)
    },
    getPaged (query) {
      return Api.getResources(query)
    }
  },

  lessons: {
    getOne (id) {
      return Api.getLesson(id)
    },
    getPaged (query) {
      return Api.getLessons(query, {})
    }
  },

  merch_locations: {
    getOne (id) {
      return Api.getMerchLocation(id)
    },
    getPaged (query) {
      return Api.getMerchLocations(query)
    }
  },

  merch_products: {
    getOne (id) {
      return Api.getMerchStoreItem(id)
    },
    getPaged (query) {
      return Api.getStoreItems({}, query)
    }
  }
}
/**
 * The method returned needs to be called with the Api object as the scope.
 * e.g. method.call(Api, ....)
 */
export function getLookup (type: LookupTableNames | string) {
  const lookup = LookupTables[type as LookupTableNames]

  if (!lookup) {
    throw new Error(`A lookup interface has not yet been defined for ${type}`)
  }

  return lookup
}
