import Vue from 'vue'
import { ValidationError } from '../api'
import ErrorModal from '../components/ErrorModal.vue'
import Modal from '../components/Modal.vue'

export default Vue.directive('async', {
  // Called only once, when the directive is first bound to the element.
  bind (el, binding, vnode) {
    const action = el.textContent?.trim() || null

    const openModal = (err: Error): Promise<void> => {
      return new Promise((resolve) => {
        const modal = vnode.context!.$buefy.modal.open({
          component: ErrorModal,
          props: { err, action },
          hasModalCard: true,
          events: {
            'on-close': () => {
              modal.close()
              resolve()
            }
          },
          onCancel: () => resolve()
        })
      })
    }

    const openAlert = (message: string): Promise<void> => {
      return new Promise((resolve) => {
        const modal = vnode.context!.$buefy.modal.open({
          component: Modal,
          props: { title: 'Validation Error', message, buttons: [{ key: 'ok', type: 'default', label: 'OK' }] },
          hasModalCard: true,
          events: {
            'on-button-click': () => {
              modal.close()
              resolve()
            }
          },
          onCancel: () => resolve()
        })
      })
    }

    const callAsyncMethod = async (e: Event) => {
      e.preventDefault()
      e.stopPropagation()

      if (el.getAttribute('disabled') === 'disabled') { return }

      // Attempt to find the actual button clicked if it's a form...
      const button = el.tagName === 'FORM' ? el.querySelector('[type=submit]:focus') as HTMLElement : null

      try {
        el.setAttribute('disabled', 'disabled')
        el.classList.add('is-loading')

        if (button) {
          button.setAttribute('disabled', 'disabled')
          button.classList.add('is-loading')
        }

        console.time('Async method: ' + (button || el).textContent)
        await binding.value.call(vnode.context)
      } catch (err: unknown) {
        if (err instanceof ValidationError) {
          return openAlert(err.message)
        }

        await openModal(err as Error)
        console.error('Async method failed:', err)
      } finally {
        console.timeEnd('Async method: ' + (button || el).textContent)

        el.classList.remove('is-loading')
        el.removeAttribute('disabled')

        if (button) {
          button.classList.remove('is-loading')
          button.removeAttribute('disabled')
        }

        if (el.tagName === 'FORM') {
          const firstInput = el.querySelector('input')

          if (firstInput) { firstInput.focus() }
        } else {
          el.focus()
        }
      }
    }

    const eventToUse = el.tagName === 'FORM' ? 'submit' : 'click'

    el.addEventListener(eventToUse, callAsyncMethod)
  }
})
