





































import Vue from 'vue'
import Component from 'vue-class-component'
import { ModalButton } from '../mixins/ModalMixin'

@Component({ props: ['title', 'message', 'label', 'defaultValue', 'buttons'] })
export default class PromptModal extends Vue {
  private value = ''

  public async mounted () {
    const buttons = this.getButtons()

    const autoFocusButton = buttons.find(b => b.autoFocus)

    if (autoFocusButton) {
      const buttonElement = this.$refs[`button-${autoFocusButton.key}`] as HTMLButtonElement[]
      await this.$nextTick()
      buttonElement[0].focus()
    }
  }

  public data () {
    return {
      value: this.$props.defaultValue || ''
    }
  }

  public getMessageLines () {
    return this.$props.message.split(/\n/g)
  }

  public getButtons (): ModalButton<string>[] {
    if (this.$props.buttons) {
      return this.$props.buttons
    }

    return [{ key: 'ok', type: 'primary', label: 'OK', autoFocus: true }]
  }

  public onButtonClick (button: ModalButton<string>) {
    this.$emit('on-button-click', button.key, this.value)
  }
}
